import React from 'react';
import FormImage from '../Form/FormImage';
import FormText from '../Form/FormText';
import FormIcon from '../Form/FormIcon';
import SectionForm from '../Form/SectionForm';
import memberLinks from './memberLinks';

const Profile = ({member, updateMember, orga}) => {

    return (
        <>{orga && member ? 
            <SectionForm title={`My profile at ${orga ? orga.name : ''}`} object={member} setObject={updateMember} >
                <FormImage fieldName='avatarUrl' path='avatars' name={`${orga.id}-${member.id}`} width={2}/>
                <FormText fieldName='email' readOnly disabled width={4} label='email' />
                <FormText fieldName='name' label='Name' width={2}/>
                <FormText fieldName='lastName' label='Last Name'  width={3}/>
                <FormText fieldName='initials' label='Initials' width={1}/>
                <FormText fieldName='phone' label='Phone' width={2} />
                <FormText fieldName='address' label='Address' width={10}/>
                {memberLinks.map(link => (
                    <FormIcon key={link.fieldName} fieldName={link.fieldName} label={link.label} icon={link.icon} width={6}/>
                ))}
            </SectionForm> : ''
        }</>
    );
}

export default Profile;