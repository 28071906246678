import { useEffect, useState } from 'react';
import { useGlobalState, setCurrentTeam, setCurrentRole } from '../globalState';
import addListener from './addListener';
import createTeam from './createTeam';
import getUpdate from './getUpdate';
import getInfoFromEmail from './getInfoFromEmail';

// return the organizations the current user is member of in an array
const useOrganizations = () => {
    const [base] = useGlobalState('base');
    const [user] = useGlobalState('currentUser');
    const [member] = useGlobalState('currentMember');
    const [documents, setDocuments] = useState([]);
    
    useEffect(() => {
        const memberOf = user ? user.memberOf : null;
        if (memberOf && memberOf.length > 0) {
            const tag = `${memberOf.join('_')}-organizations`;
            const collectionRef = base.collection('organizations');
            const ref = {
                tag, 
                query: collectionRef.where('__name__', 'in', memberOf),
            }
            return addListener(ref, {update: docs => {setDocuments(docs)}});
        }
    }, [user, base]);

    const createOrganization = async name => {
        if (user && name) {
            const batch = base.batch();
            // change default for decision tracking (getUpdate)
            await setCurrentRole({name: 'Creator'});
            await setCurrentTeam({name: 'Organization'});
            // A) create references
            const userRef = base.collection('users').doc(user.id);
            const newOrgaRef = base.collection('organizations').doc();
            const orgaId = newOrgaRef.id;
            const orgaRef = base.collection('organizations').doc(orgaId);
            const memberRef = orgaRef.collection('members').doc(user.id);
            // B) create document updates
            const [teamId, leaderId, refereeId, secretaryId] = createTeam(base, 'First team', orgaId, user, null, batch);
            const newMemberOf = user.memberOf ? [orgaId].concat(user.memberOf) : [orgaId];
            const newOrga = getUpdate({
                name,
                active: true,
                createdOn: Date.now(),
                createdBy: user.email,
                firstTeam: teamId,
            });
            const memberInfo = getInfoFromEmail(user.email);
            const newMember = getUpdate({
                email: user.email,
                name: user.name || memberInfo.name,
                lastName: user.lastName || memberInfo.lastName,
                initials: user.initials || memberInfo.initials,
                active: true,
                defaultTeam: teamId,
                defaultRole: leaderId,
                teams:[teamId],
                roles: [
                    leaderId,
                    refereeId,
                    secretaryId
                ],
            });
            const userUpdate = {
                memberOf: newMemberOf, 
                defaultOrga: orgaId,
            }
            // C) Write updates through the batch
            //console.log('userUpdate', userUpdate);
            //console.log('newOrga', newOrga);
            //console.log('newMember ', newMember);
            batch.set(userRef, userUpdate, {merge: true});
            batch.set(memberRef, newMember);
            batch.set(newOrgaRef, newOrga);
            return batch.commit(); // return the promise
        } else {
            throw new Error('No given mame or current user');
        }    
    }

    const leaveOrganization = (orgaId) => {
        if (user && orgaId && member) {
            // NB we're note deleting the organization when there is no member left yet
            const batch = base.batch();
            const newMemberOf = user.memberOf.filter(item => item !== orgaId);
            const newDefaultOrga = orgaId === user.defaultOrga ? 
                                                newMemberOf.length > 0 ? newMemberOf[0] : null :
                                                user.defaultOrga;
            const orgaRef = base.collection('organizations').doc(orgaId);
            const memberRef = orgaRef.collection('members').doc(user.id);
            const usersRef = base.collection('users').doc(user.id);
            batch.update(usersRef, {memberOf: newMemberOf, defaultOrga: newDefaultOrga});
            batch.update(memberRef, {active: false, modifiedBy: user.email});
            return batch.commit(); // return the promise
        }      
    }
    return [documents, createOrganization, leaveOrganization];
}

export default useOrganizations;