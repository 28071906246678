import { useState, useEffect } from 'react';

// filtering 'docs' (an array of objects) looking for 'filter' string in any of the object 
// properties values with key name is present in 'fields' (an array of strings)

const useFilter = (docs, fields, filter) => {
    const [filtered, setFiltered] = useState(docs);

    useEffect( () => {
        const filters = filter ? filter.trim().toLowerCase().split(' ') : null;
        if (!filters) {
            setFiltered(docs);
        } else if (docs && fields) {
            const newFiltered = docs.filter(doc => {
                const s = Object.entries(doc)
                                .reduce((accu, [key, value]) => fields.includes(key) ? accu+value : accu, '')
                                .toLowerCase();
                //console.log('Testing useFilter ', doc, s);
                //return s.includes(filters[0]);
                return filters.reduce((a, f) => a && s.includes(f), true);
            });
            //console.log('useFilter, refreshing filtered: ', newFiltered);
            setFiltered(newFiltered);
        }
    }, [filter, docs, fields]);

    return filtered;
}

export default useFilter;