import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import {Grid, IconButton, Tooltip } from '@material-ui/core';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteIcon from '@material-ui/icons/Delete';
import DecideIcon from '@material-ui/icons/Gavel';
import GlobalIcon from '@material-ui/icons/Public';
import Form from '../Form/Form';
import FormText from '../Form/FormText';
import FormDate from '../Form/FormDate';
import usePosts from '../../base/usePosts';
import buttons from './buttons';
import Post from './Post';
import TopicAction from './TopicAction';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '20px',
    },
    buttons: {
        paddingTop: '20px',
        paddingBottom: '12px',
        paddingRight: '10px',
    },
    text: {
        color: theme.palette.primary.contrastText,
        paddingLeft: '20px',
    },
    decisions: {
        textAlign: 'center',
        paddingTop: '20px',
    },
}));

const Topic = ({team, role, topic, updateTopic, archiveTopic, deleteTopic, color}) => {
    const [activeButton, setActiveButton] = useState(null);
    const [post, setPost] = useState({});
    const classes = useStyles();
    const [posts, addPost] = usePosts(topic.id);    
    const isTheAuthor = topic.author.name === role.name;

    const handlePost = (object) => {
        setPost({});
        setActiveButton(null);
        const prom = addPost({
            type: activeButton, 
            createdBy: role.id,
            roleSymbol: role.symbol,
            roleName: role.name,
            ...object
        });
        if (prom) {
            prom.then(() => console.log('post added')).catch(error => console.log('Error on adding post: ', error));
        }
    }

    const action = (name) => {
        if (name !== activeButton) {
            setActiveButton(name);
        } else {
            setActiveButton(null);
        }
    }

    return (
        <div className={classes.root} >
            <Form 
                title={`Topic ${topic.title}`} 
                object={topic} 
                setObject={updateTopic} 
                startOpen
            >
                <FormText fieldName='title' width={4} disabled={!isTheAuthor} label='Title' />
                <FormDate value={topic.createdOn} disabled={true} width={4} label='Created on'/>
                <FormText value={topic.author.name} disabled={true} width={4} label='Created by' />
                <FormText fieldName='description' disabled={!isTheAuthor} multiline row={3} label='Rationale' width={12}/>
                <FormText fieldName='proposition' disabled={!isTheAuthor} multiline row={3} label='Proposed decision' width={12}/>
            </Form>
            <Grid container >
                <Grid item xs={3} className={classes.buttons} >
                    {isTheAuthor ? '' : 
                        Object.entries(buttons).map(([field, value]) => 
                            <Tooltip key={field} title={value.label}>
                                <IconButton 
                                    size='small'
                                    onClick={() => action(field)} 
                                    style={{color: field === activeButton ? buttons[activeButton].color : color}}
                                >
                                    {value.icon}                    
                                </IconButton>
                            </Tooltip>
                    )}
                </Grid>
                <Grid item xs={9} >
                    {activeButton ? 
                        <Form object={post} setObject={handlePost}>
                            <FormText fieldName='text' label='Your post' width={8} />
                        </Form> : ''
                    }
                </Grid>
                {posts.map(post => <Post key={post.id} post={post} color={color} />)}
                <Grid container item xs={12} className={classes.decisions}>
                    <TopicAction
                        onClick={() => archiveTopic({decision: {status: 'decided', scope: 'team', teamId: team.id}})}
                        disabled={!isTheAuthor}
                        icons={<><DecideIcon />&nbsp;</>}
                        title='mark as a decision visible to the team only'
                    />
                    <TopicAction
                        onClick={() => archiveTopic({decision: {status: 'decided', scope: 'orga'}})}
                        disabled={!isTheAuthor}
                        icons={<><DecideIcon />&nbsp; <GlobalIcon />&nbsp;</>}
                        title='mark as a decision visible to the whole organization'
                    />
                    <TopicAction
                        onClick={() => archiveTopic({decision: {status: 'postponed'}})}
                        disabled={false}
                        icons={<><ArchiveIcon />&nbsp;</>}
                        title='just archive without marking as a decision'
                    />
                    <TopicAction
                        onClick={deleteTopic}
                        disabled={false}
                        icons={<><DeleteIcon />&nbsp;</>}
                        title='delete topic permanently'
                        alert
                    />
                </Grid> 
            </Grid>
        </div>
    );
}

export default Topic;