import firebase from 'firebase/app';
import { useEffect, useState } from 'react';
import { useGlobalState } from '../globalState';
import addListener from './addListener';
import createTeam from './createTeam';
import useRoles from './useRoles';
import getUpdate from './getUpdate';

const getSiblings = (teams, teamId) => {
    const team = teams.find(item => item.id === teamId);
    let siblings = team && team.children ? [...team.children] : [];
    siblings.forEach(childId => {
        siblings = [...siblings, ...getSiblings(teams, childId)];
    })
    return siblings;
}

// return the role documents from {defaultOrgaId}-roles and a
// function to add a new one. 
const useTeams = () => {
    const [base] = useGlobalState('base');
    const [user] = useGlobalState('currentUser');
    const [roles] = useRoles();
    const [teams, setTeams] = useState([]);
    const orgaId = user ? user.defaultOrga : null;
    const orgaRef = base && orgaId ? base.collection('organizations').doc(orgaId) : '';

    useEffect(() => {
        if (orgaId) {
            const tag = `${orgaId}-teams`;
            const collectionRef = orgaRef.collection('teams');
            const ref = {
                tag, 
                query: collectionRef.where('active', '==', true),
            }
            return addListener(ref, {update: docs => {setTeams(docs)}});
        } else {
            setTeams([]); // when logout
        }
    }, [orgaId, orgaRef]);

    // To create a new team as a child of the current team
    const addTeam = (name, parent) => {
        console.log('useTeams addTeam: ', name, parent, orgaId, teams);
        if (orgaId && teams) {
            const batch = base.batch();
            const memberRef = orgaRef.collection(`members`).doc(user.id);
            const parentRef = orgaRef.collection(`teams`).doc(parent.id);
            const [newTeamId, leaderId, refereeId, secretaryId] = createTeam(base, name, orgaId, user, parent.id, batch);
            batch.update(memberRef, getUpdate({
                roles: firebase.firestore.FieldValue.arrayUnion(leaderId, refereeId, secretaryId),
                teams: firebase.firestore.FieldValue.arrayUnion(newTeamId),
            }));
            batch.update(parentRef, getUpdate({
                children: firebase.firestore.FieldValue.arrayUnion(newTeamId), 
            }, parent));
            return batch.commit(); // return the promise
        }        
    }

    const setTeam = (id, update) => {
        //console.log('useTeams setTeam: ', id, update);
        if (id && update) {
            const team = teams.find(doc => doc.id === id);
            const teamRef = orgaRef.collection(`teams`).doc(id);
            const batch = base.batch();
            if (update.parent && update.parent !== id) {
                //console.log('modifying the parent: ', team.parent, update.parent);
                const siblings = getSiblings(teams, id);
                if (siblings.find(t => t === update.parent) === undefined) {
                    const formerParent = teams.find(t => t.id === team.parent);
                    const formerParentRef = orgaRef.collection(`teams`).doc(team.parent);
                    const formerParentUpdate = getUpdate({
                        children: firebase.firestore.FieldValue.arrayRemove(id),
                    }, formerParent);
                    //console.log('formerParentUpdate ', formerParent, formerParentUpdate);
                    batch.update(formerParentRef, formerParentUpdate);
                    const newParent = teams.find(t => t.id === update.parent);
                    const newParentRef = orgaRef.collection(`teams`).doc(update.parent);
                    const newParentUpdate = getUpdate({
                        children: firebase.firestore.FieldValue.arrayUnion(id),
                    }, newParent);
                    //console.log('newParentUpdate ', newParent, newParentUpdate);
                    batch.update(newParentRef, newParentUpdate);
                } else {
                    console.log("Can't attach a team to one of its descendants");
                    update.parent = team.parent;
                }
            }
            const newUpdate = getUpdate(update, team);
            //console.log('newUpdate ', team, newUpdate);
            batch.update(teamRef, newUpdate);
            return batch.commit(); // return the promise
        }        
    }

    const deleteTeam = (id) => { 
        if (!id || !teams || !roles) {
            return null;
        }
        const team = teams.find(item => item.id === id);
        if (!team || !team.parent) {
            return null; // can't delete the first team
        }
        const parent = teams.find(item => item.id === team.parent);
        const batch = base.batch();
        const teamRef = orgaRef.collection(`teams`).doc(id);
        const parentRef = orgaRef.collection(`teams`).doc(team.parent);
        team.roles.forEach(roleId => {
            const roleRef = orgaRef.collection(`roles`).doc(roleId);
            const role = roles.find(item => item.id === roleId);
            if (!role || !role.holder) {
                return null; // some data descrepency 
            }
            const memberRef = orgaRef.collection(`members`).doc(role.holder);
            batch.update(memberRef, getUpdate({
                roles: firebase.firestore.FieldValue.arrayRemove(roleId),
            }));
            batch.update(roleRef, getUpdate({holder: null, active: false}));
        })
        batch.update(parentRef, getUpdate({
            children: firebase.firestore.FieldValue.arrayRemove(id),
        }, parent));
        batch.update(teamRef, getUpdate({active: false}, team));
        return batch.commit(); // return the promise
    }
    return [teams, addTeam, setTeam, deleteTeam];
}

export default useTeams;