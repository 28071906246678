import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import DarkIcon from '@material-ui/icons/Brightness4';
import LightIcon from '@material-ui/icons/Brightness7';
import Section from '../Section';
import logout from '../../base/logout';
import Form from '../Form/Form';
import FormText from '../Form/FormText';

const useStyles = makeStyles((theme) => ({
    logout: {
        display: 'flex',
        alignItems: 'right',
        padding: '10px',
    },
    spacer: {
        flex: 1,
    },
}));


const Settings = ({darkMode, switchMode, user, updateUser}) => {
    const history = useHistory();
    const classes = useStyles();

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
  
    const handleClose = () => {
        setOpen(false);
    };
    
    const handleLogout = () => {
        setOpen(false);
        logout();
        history.push('/');
    }

    return (
        <Section title='My settings' >
            <Form object={user} setObject={updateUser} >
                <FormText fieldName='authorName' label='Author name for templates' width={5}/>
            </Form>
            <div className={classes.logout} >
                <Button 
                    onClick={() => switchMode()}
                >
                    {darkMode ?
                        <> <LightIcon />&nbsp;bright mode </> : 
                        <> <DarkIcon />&nbsp;dark mode </>
                    }
                </Button>
                <span className={classes.spacer} />
                <Button 
                        color='secondary' 
                        onClick={handleClickOpen}
                        className={classes.logout}
                    >
                        Logout&nbsp; <LogoutIcon />  
                </Button>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Log out from Yorga</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        If you log out you will have to receive a new access link via email
                        to sign in again.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="inherit">
                    Cancel
                </Button>
                <Button onClick={handleLogout} color="secondary" autoFocus>
                    Logout
                </Button>
                </DialogActions>
            </Dialog>
        </Section>
    );
}

export default Settings;
