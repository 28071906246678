import getUpdate from './getUpdate';

const createTeam = (base, name, orgaId, user, parentId, batch) => {
    // A) create references
    const orgaRef = base.collection('organizations').doc(orgaId);
    const teamRef = orgaRef.collection(`teams`).doc();
    const leaderRef = orgaRef.collection(`roles`).doc();
    const refereeRef = orgaRef.collection(`roles`).doc();
    const secretaryRef = orgaRef.collection(`roles`).doc();
    // B) create document updates
    const team = getUpdate({
        name,
        roles: [
            leaderRef.id,
            refereeRef.id,
            secretaryRef.id
        ],
        children: [],
        purpose: 'To be defined',
        duties: ['First duty...'],
        parent: parentId,
        active: true,
    });
    const leaderRole = getUpdate({
        name: 'Leader',
        holder: user.id,
        team: teamRef.id,
        symbol: 'leader',
        duties: [
            'I embody the purpose of the team and make sure the decisions made by the team are aligned with this purpose', 
            'I name or to have named through volunteering or votes the holders of the team roles',
            'I am the default holder of any role in the team until somebody else is named'
        ],
        active: true,
    });
    const refereeRole = getUpdate({
        name: 'Referee',
        holder: user.id,
        team: teamRef.id,
        symbol: 'referee',
        duties: [
            'I make sure the team is playing by the rules and principles of yorga',
            'I act as a time keeper and make sure each role can express itself',
            "If needed I arbitrate on objections based on involved duties and policies"
        ],
        active: true,
    });
    const secretaryRole = getUpdate({
        name: 'Secretary',
        holder: user.id,
        team: teamRef.id,
        symbol: 'secretary',
        duties: [
            'I organize the life of the team by scheduling meetings and events',
            'I make sure the discussions and decisions are properly traced',
            "I handover on Referee's duties when the referee's holder is proposing or objecting to a proposition"
        ],
        active: true,
    });
    // C) Write all updates through the batch
    batch.set(teamRef, team);
    batch.set(leaderRef, leaderRole);
    batch.set(refereeRef, refereeRole);
    batch.set(secretaryRef, secretaryRole);
    return [teamRef.id, leaderRef.id, refereeRef.id, secretaryRef.id];  
}

export default createTeam;
