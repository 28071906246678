import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import TeamIcon from '@material-ui/icons/GroupWork';
import { getColor } from '../../theme';
import { useGlobalState } from '../../globalState';

const TeamAvatar = ({team, size}) => { 
    const color = team && team.color ? team.color : {hue: 'green', darkShade: '600', lightShade: '400'};
    const [user] = useGlobalState('currentUser');
    const dark = user ? user.darkMode : false;
    const rgbColor = getColor(color, dark);
    const name = team.name || 'unknown';
    const initials = name.split(' ').reduce((acc, val) => acc+val.charAt(0), '').toUpperCase();

    return (
        <>
            { team ?
                <Avatar 
                    name={name}  
                    src={team.avatarUrl} 
                    style={{
                        color: dark ? "#FFF" : "#000", 
                        background: rgbColor, 
                        width: size || 40, 
                        height: size || 40,
                        fontSize: size ? size / 2 : 20,
                        marginLeft: size ? '6px' : '0px',
                    }}
                >
                    {initials}
                </Avatar> :
                <TeamIcon />
            }
        </>
    )
}

export default TeamAvatar;