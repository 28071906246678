import * as isEqual from 'lodash.isequal';
import { getGlobalState } from '../globalState';


// object is optional, it serves to reduce the update to a dif
const getUpdate = (update, object) => {
    const user = getGlobalState('currentUser');
    const role  = getGlobalState('currentRole');
    const team  = getGlobalState('currentTeam');

    const newUpdate = {
        modifiedName: object ?  object.name || object.destEmail || object.title || 'unknown' : 
                                update.name || update.destEmail || update.title || 'unknown', 
        modifiedBy: user ? user.email : 'unknown', // if unknown, the request should not pass
        modifiedAs: role ? role.name : 'no role', 
        modifiedIn: team ? team.name : 'no team',
    };
    
    for (const [key, value] of Object.entries(update)) {
        if (key !== 'modifiedBy' 
            && (!object || !object[key] || !isEqual(object[key], update[key])) ) {  // deep comparaison using Lodash  
                newUpdate[key] = value;
        } 
    }
    return newUpdate
}

export default getUpdate; 