import React from 'react';
import {Grid, Button, Tooltip } from '@material-ui/core';

const TopicAction = ({disabled, onClick, icons, title, alert}) => (
    <Grid item xs={3}>
        {disabled ?
            <Button 
                onClick={onClick}
                variant='contained' 
                size='small'
                disabled
            >
                {icons} 
            </Button> :
            <Tooltip title={title}>
                <Button 
                    onClick={onClick}
                    variant='contained' 
                    color={alert ? 'secondary' : 'primary'}
                    size='small'
                >
                    {icons}
                </Button>
            </Tooltip>
        }
    </Grid>
);

export default TopicAction;