export const LANDING = '/'
export const HOME = '/welcome'
export const SIGN_OUT = '/signout'
export const SIGN_IN_FORM = '/signinform'
export const SIGN_IN_CHECK = '/signincheck'
export const ORGA = '/orga'
export const TEAMS = '/teams'
export const ROLES = '/roles'
export const DECISIONS = '/decisions'
export const DIRECTORY = '/directory'
export const CHART = '/chart'
export const ME = '/me'
export const TEMPLATES = '/templates'
export const BASECHECK = '/basecheck'
export const CONTACT = 'mailto:yves.christol@gmail.com';
