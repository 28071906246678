import React from 'react';
import TextField from '@material-ui/core/TextField';
import ViewText from './ViewText';

const FormText = (props) => {
    const {setValue, fieldName, disabled, width, value, ...rest} = props; // must NOT send injected props to DOM

    const handleChange = (event) => {
        if (!setValue || !fieldName) {
            console.log('Values are not properly injected');
        }
        event.preventDefault();
        setValue(event.target.value);
    }

    return (
        <>
            {setValue && !disabled ? 
                <TextField 
                    onChange={handleChange}
                    value={value}
                    style={{width: '100%'}}
                    {...rest} 
                /> :
                <ViewText label={props.label} text={value} />
            }
        </>
    );
}

export default FormText;
