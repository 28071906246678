import React, { useState } from 'react';
import Form from '../Form/Form';
import FormText from '../Form/FormText'
import Typography from '@material-ui/core/Typography';
import Chips from '../Chips';
import useOrgaInvitations from '../../base/useOrgaInvitations';

const InviteForm = () => {
    const [object, setObject] = useState({email: ''});
    const [invitations, addInvitation, deleteInvitation] = useOrgaInvitations(); // pending invitations for this orga

    const handleInvite = object => {
        console.log('handleInvite:');
        const emails = object.email.replace(/[,;]/g, ' ').trim().replace(/\s\s+/g, ' ').split(' ');
        emails.forEach(email => {
            addInvitation(email)
                .then(() => {
                    console.log(`Added invitation for ${email}`);
                }).catch( error => {
                    console.log(`Error when adding invitation: ${error}`)
                })
        })
        //console.log(emails);
        setObject({email: ''});
    }

    return (
        <div >
            <Form object={object} setObject={handleInvite} >
                <FormText 
                    fieldName='email'
                    label='email'
                    helperText='Enter and submit one or several email addresses separated by spaces, tabs, end of lines, comas or semicolons.'
                    width={9}
                />
            </Form>
            <Typography >
                {invitations && invitations.length > 0 ? 'Pending invitations:' : 'No pending invitations'}
            </Typography>
            <Chips 
                values={invitations}
                label='destEmail'
                onDelete={deleteInvitation}
            />
        </div>
    );

}

export default InviteForm;
