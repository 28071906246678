import { useEffect, useState } from 'react';
import { useGlobalState, setCurrentUser, setCurrentOrga, setCurrentTeam, setCurrentRole, setCurrentMember } from '../globalState';
import addListener from './addListener';
import getUpdate from './getUpdate';

// return object with current user, orga, member, team and role info
// and functions to update these, debouncing the result to limit 
// multiple successive rendering.
const useCurrent = () => {
    const [base] = useGlobalState('base');
    const [authUser] = useGlobalState('currentAuthUser');
    const [user, setUser] = useState(null);
    const [orga, setOrga] = useState(null);
    const [member, setMember] = useState(null);
    const [team, setTeam] = useState(null);
    const [role, setRole] = useState(null);

    useEffect(() => {
        const userId = authUser ? authUser.uid : null;
        if (base && userId) {
            const tag = `users-${userId}`;
            const collectionRef = base.collection('users');
            const ref = {
                tag, 
                query: collectionRef.where('__name__', '==', userId),
            }
            return addListener(ref, {update: doc => {
                const newUser = {id: userId, email: authUser.email, ...doc};
                setUser(newUser);
                //setTimeout(() => setUser(newUser), 100); // to avoid firestore rules issues
            }, id: userId});
        } else {
            setUser(null);
        }
    }, [base, authUser]);

    useEffect(() => {
        const orgaId = user ? user.defaultOrga : null;
        const memberOf = user ? user.memberOf : null;
        if (base && memberOf && memberOf.length > 0) {
            const tag = `${memberOf.join('_')}-organizations`;
            const collectionRef = base.collection('organizations');
            const ref = {
                tag, 
                query: collectionRef.where('__name__', 'in', memberOf),
            }
            return addListener(ref, {update: doc => {
                doc.id = orgaId; 
                setOrga(doc);
            }, id: orgaId});
        }
        if (!orgaId) {
            setOrga(null); // when logout
        }
    }, [base, user]);

    useEffect(() => {
        const userId = user ? user.id : null;
        const orgaId = user ? user.defaultOrga : null;
        const orgaRef = orgaId ? base.collection('organizations').doc(orgaId) : null;
        if (orgaRef) {
            const tag = `${orgaId}-members`;
            const collectionRef = orgaRef.collection('members');
            const ref = {
                tag, 
                query: collectionRef.orderBy('name'),
            }
            return addListener(ref, {update: doc => {
                doc.id = userId;
                setMember(doc);
            }, id: userId});
        } else {
            setMember(null); // when logout
        }
    }, [base, user]);

    useEffect(() => {
        const orgaId = user ? user.defaultOrga : null;
        const orgaRef = orgaId ? base.collection('organizations').doc(orgaId) : null;
        const teamId = member ? member.defaultTeam : null;
        if (orgaRef && teamId) {
            const tag = `${orgaId}-teams`;
            const collectionRef = orgaRef.collection('teams');
            const ref = {
                tag, 
                query: collectionRef.orderBy('name'),
            }
            return addListener(ref, {update: doc => {
                doc.id = teamId; 
                setTeam(doc);
            }, id: teamId});
        } else {
            setTeam(null); // when logout
        }
    }, [base, user, member]);

    useEffect(() => {
        const orgaId = user ? user.defaultOrga : null;
        const orgaRef = orgaId ? base.collection('organizations').doc(orgaId) : null;
        const roleId = member ? member.defaultRole : null;
        if (orgaRef && roleId) {
            const tag = `${orgaId}-roles`;
            const collectionRef = orgaRef.collection('roles');
            const ref = {
                tag, 
                query: collectionRef.orderBy('name'),
            }
            return addListener(ref, {update: doc => {
                doc.id = roleId; 
                setRole(doc);
            }, id: roleId});
        } else {
            setRole(null); // when logout
        }
    }, [base, user, member]);

    useEffect(() => {
        //console.log('user refreshed: ', user);
        setCurrentUser(user);
    }, [user]);

    useEffect(() => {
        //console.log('orga refreshed: ', orga);
        setCurrentOrga(orga);
    }, [orga]);

    useEffect(() => {
        //console.log('member refreshed: ', member);
        setCurrentMember(member);
    }, [member]);

    useEffect(() => {
        //console.log('team refreshed: ', team);
        setCurrentTeam(team);
    }, [team]);

    useEffect(() => {
        //console.log('role refreshed: ', role);
        setCurrentRole(role);
    }, [role]);

    // Can only be used to update your own user information !
    const updateUser = update => {
        const userId = authUser ? authUser.uid : null;
        if (userId) {
            return base.collection('users').doc(userId).set(update, {merge: true}); // return the promise
        }        
    }

    // To update current orga settings
    const updateOrga = update => {
        const orgaId = user ? user.defaultOrga : null;
        if (orgaId) {
            const orgaRef = orgaId ? base.collection('organizations').doc(orgaId) : null;
            return orgaRef.set(getUpdate(update, orga), {merge: true}); // return the promise
        }        
    }
    
    // To update current member
    const updateMember = update => {
        const orgaId = user ? user.defaultOrga : null;
        const userId = authUser ? authUser.uid : null;
        if (userId) {
            const orgaRef = orgaId ? base.collection('organizations').doc(orgaId) : null;
            return orgaRef.collection('members').doc(userId).set(getUpdate(update, member), {merge: true}); // return the promise
        }        
    }

    // To update current team
    const updateTeam = update => {
        const orgaId = user ? user.defaultOrga : null;
        const teamId = member ? member.defaultTeam : null;
        if (teamId) {
            const orgaRef = orgaId ? base.collection('organizations').doc(orgaId) : null;
            return orgaRef.collection('teams').doc(teamId).set(getUpdate(update, team), {merge: true}); // return the promise
        }        
    }

    // To update current role (no creation nor delete)
    const updateRole = update => {
        const orgaId = user ? user.defaultOrga : null;
        const roleId = member ? member.defaultRole : null;
        if (roleId) {
            const orgaRef = orgaId ? base.collection('organizations').doc(orgaId) : null;
            return orgaRef.collection('roles').doc(roleId).set(getUpdate(update, role), {merge: true}); // return the promise
        }        
    }

    return {user, orga, member, team, role, updateUser, updateOrga, updateMember, updateTeam, updateRole};
}

export default useCurrent;

