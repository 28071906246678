import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Form from '../Form/Form';
import FormTeam from '../Form/FormTeam';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: '20px',
    },
    left: {
        display: 'flex',
        textAlign: 'left',
    },
    typo: {
        padding: '10px',
    }
}));

const TeamInfo = ({team, setTeam}) => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} spacing={2} justify='center' >
            <Grid item xs={4} className={classes.left} >
                <Avatar src={team.avatarUrl} variant='square'>
                    {team.name && team.name.length > 1 ? team.name[0] : '?'}
                </Avatar>
                <Typography className={classes.typo} >
                    {team.name}
                    <small>{` (${team.roles.length} roles)`}</small>
                </Typography>
            </Grid>
            <Grid item xs={6} >
                <Form object={team} setObject={update => setTeam(team.id, update)} >
                    <FormTeam fieldName='parent' label='parent team' width={9}/>
                </Form>
            </Grid>
        </Grid>
    )
}

export default TeamInfo;