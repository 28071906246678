import React from 'react';
import Avatar from '@material-ui/core/Avatar';

const OrgaAvatar = ({orga}) => {
    const imgUrl = orga && orga.logoUrl ? orga.logoUrl : null;

    return ( 
        <Avatar name={orga.name} variant='square' src={imgUrl} >
            {orga && orga.name ? orga.name[0] : 'O'}
        </Avatar>
    )
}

export default OrgaAvatar;