import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import useMyInvitations from '../../base/useMyInvitations';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
        padding: '20px',
    },
}));

const InvitationManager = () => {
    const [invitations, acceptInvitation, rejectInvitation] = useMyInvitations();
    const classes = useStyles();

    return (
        <>
            {invitations && invitations.length > 0 ?
                <div className={classes.root}>
                    <Typography >
                        Pending invitations 
                    </Typography>
                    {invitations.map( (invite, idx) => 
                        <Chip 
                            key={idx} 
                            label={invite.orgaName} 
                            color='primary'
                            onClick={() => {acceptInvitation(invite)}}
                            onDelete={() => {rejectInvitation(invite)}}                                            
                        />
                )}
                </div> : ''
            }
        </>

    );

}

export default InvitationManager;