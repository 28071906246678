import React, { useState } from 'react';
import Symbol, { getSymbols, randomSymbol } from '../icons/Symbol';
import { IconButton, Dialog, List, ListItem } from '@material-ui/core';
import IconDice from '@material-ui/icons/Casino'
import IconPick from '@material-ui/icons/ViewComfy';
import { useGlobalState } from '../../globalState';

const FormSymbol = ({value, setValue, size}) => {
    const [open, setOpen] = useState(false);
    const [theme] = useGlobalState('theme');
    const color = theme ? theme.palette.primary.contrastText : '#F00';

    const handleOpen = () => {
        setOpen(true);
    }

    const handlePick = (symbol) => {
        setValue(symbol);
        setOpen(false);
    }

    const handleRandom = () => {
        setValue(randomSymbol());
    }

    return (
        <>
            <Symbol name={value || 'new'} size ={size || 30} color={color} />
            { setValue ?
                <>
                    <IconButton size='small' onClick={handleRandom} >
                        <IconDice />
                    </IconButton>
                    <IconButton size='small' onClick={handleOpen} >
                        <IconPick />
                    </IconButton> 
                </>: ''
            }
            <Dialog
                aria-labelledby="symbol picker"
                open={open}
                BackdropProps={{ style: { backgroundColor: "transparent" } }}
            >
                 <List>
                    { getSymbols().map( (symbol, idx) => (
                        <ListItem
                            button
                            onClick={() => handlePick(symbol[0])}
                            key={idx}
                        >
                            <Symbol name={symbol[0]} size='12' key={idx} />
                        </ListItem>
                    ))}
                </List>
            </Dialog>
        </>
    );
}

export default FormSymbol;