import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import Linkify from 'react-linkify';
import componentDecorator from './materialComponentDecorator';

const ViewText = ({label, text, small}) => (
    <Grid container>
        <Grid item xs={12}>
            <Typography variant='body2' color='textSecondary' >
                {label}
            </Typography>
        </Grid>
        <Grid item xs={12} >
            <Linkify componentDecorator={componentDecorator} >
                <Typography >
                    {text}
                    {small ? <small>{small}</small> : ''}
                </Typography>
            </Linkify>
        </Grid>
    </Grid>
);

export default ViewText;