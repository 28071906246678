import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CameraIcon from "@material-ui/icons/PhotoCamera";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { useGlobalState } from "../../globalState";
import Spinner from "../Spinner";

const useStyles = makeStyles(theme => ({
    camera: {
        padding: '10px',
    },
    image: {
        height: '80px',
    },
    hidden: {
        position: 'absolute',
        top: '-9999px',
    },
    input: {
        //   display: 'none',
        margin: theme.spacing(1)
    }
}));

// Here the value is the image url...
const FormImage = ({path, name, value, setValue, label}) => {
    const [image, setImage] = useState(null);
    const inputEl = useRef(null);
    const [loading, setLoading] = useState(false);
    const [retrying, setRetrying] = useState(0);
    const [fullName, setFullName] = useState('');
    const [error, setError] = useState("");
    const [storage] = useGlobalState('storage');
    const [orga] = useGlobalState('currentOrga');
    const classes = useStyles();

    useEffect(() => {
        if (image && storage) {
            // Ref to the image must be set
            const ref = storage.ref().child(fullName);
            // add to image folder in firebase
            const uploadTask = ref.put(image);
            // Listen for state changes, errors, and completion of the upload.
            uploadTask.on(
                "state_changed",
                snapshot => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    console.log(`Uploading file: ${progress}`);
                },
                error => {
                    // error function ....
                    console.log(error);
                    setError(error);
                    setLoading(false);
                },
                () => {
                    // complete function ....
                    setImage(null);
                    setRetrying(10);
                }
            );
        } else {
            if (retrying > 0) {
                const smallRef = storage.ref().child(`${fullName}_160x160`);
                setTimeout( () => {
                    smallRef.getDownloadURL() // get download url
                        .then(newUrl => {       
                            setValue(newUrl);            
                            setLoading(false);
                        })
                        .catch(error => {
                            console.log(error);
                            console.log('Retrying in 1s... ');
                            setRetrying(retrying - 1);
                        })
                }, 1000);
            }
        }
    }, [storage, fullName, image, retrying, setValue]); 

    const handleChange = event => {
        const file = event.target.files[0];
        if (file) {
            const fileType = file["type"];
            const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
            if (validImageTypes.includes(fileType)) {
                setError("");
                setImage(file);
                setFullName(`orga/${orga.id}/${path}/${name}-${Date.now()}`);
                setLoading(true);
            } else {
                console.log("error");
                setError("error please upload a image file");
            }
        }
    };

    const handleClick = () => {
        inputEl.current.click();
    }

    return (
        <span >
            {error ?
                <Typography color='secondary'>{error}</Typography> : ''
            }
            <input 
                accept="image/*" 
                type="file" 
                onChange={handleChange} 
                id="file-picker"  
                ref={inputEl}
                className={classes.hidden}
                label={label || 'image'}
            />
            <img 
                className={classes.image}
                src={value || '/default-image.png'}
                alt="content"
            />
            { setValue && !loading ? 
                <IconButton size='small' className={classes.camera} onClick={handleClick}>
                    <CameraIcon />
                </IconButton> : ''
            }
            <Spinner on={loading} />
        </span>
    );
}

export default FormImage;