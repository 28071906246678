import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar'
import useMembers from '../../base/useMembers';
import Section from '../Section';
import Chips from '../Chips';
import FormText from '../Form/FormText';
import SectionForm from '../Form/SectionForm';
import FormList from '../Form/FormList';
import FormImage from '../Form/FormImage';
import FormColor from '../Form/FormColor';
import Member from '../Directory/Member';
import Children from './Children';
import Topics from '../Topics';
import Archives from '../Topics/Archives';

const Teams = ({user, team, setTeam}) => {
    const [members] = useMembers();
    const [activeMember, setActiveMember] = useState(null);
    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        if (team && members && members.length > 0) {
            const newTeamMembers = members.filter(member => 
                member.teams.includes(team.id)
            );
            //console.log('Teams, refreshing teamMembers: ', newTeamMembers, team, members);
            setTeamMembers(newTeamMembers);
        };
    }, [team, members]);

    const handleSelect = val => {
        setActiveMember(!activeMember || val.id !== activeMember.id ? val : null);
    }

    return (
        <>
            {team ?
                <SectionForm title={`${team.name}`} object={team} setObject={setTeam} startOpen>
                    <FormImage
                        fieldName='avatarUrl'
                        label='Image'
                        size='80'
                        path='teams'
                        name={team.id}
                    />
                    <FormText 
                        fieldName='name'
                        label='team name'
                    />
                    <FormText 
                        fieldName='purpose'
                        label='purpose'
                        width={6}
                    />
                    <FormList
                        fieldName='duties'
                        label='duty'
                        width={12}
                    />
                    <FormColor 
                        fieldName='color'
                        label='color'
                    />
                </SectionForm> : ''
            }
            <Topics team={team} />
            <Section title={`${team ? team.name : '...'} members`} >
                <Chips
                    values={teamMembers}
                    active={activeMember ? activeMember.id : ''}
                    onSelect={handleSelect}
                    avatar={member => 
                        <Avatar 
                            alt={member.name} 
                            src={member.avatarUrl} 
                        >
                            {member.initials}
                        </Avatar>
                    }
                    isOutlined={item => item.id !== user.id}
                />
                {activeMember ? <Member member={activeMember} /> : ''}
            </Section>
            <Children team={team} />
            <Archives team={team} />
        </>
    );
}

export default Teams;