import React from 'react';
import { render } from 'react-dom';
import initBase from './base/initBase';
import App from './components/App';

//console.log('Starting... '+process.env.PUBLIC_URL);
 
initBase();

render( 
  <div>
    <App />
  </div>,
  document.getElementById('root')
);
