import { useEffect, useState } from 'react';
import { useGlobalState } from '../globalState';
import addListener from './addListener';

const useDecisions = () => {
    const [base] = useGlobalState('base');
    const [orga] = useGlobalState('currentOrga');
    const [documents, setDocuments] = useState([]);
    const orgaRef = base && orga ? base.collection('organizations').doc(orga.id) : null;

    useEffect(() => {
        if (orgaRef) {
            const tag = `${orga.id}-decisions`;
            const collectionRef = orgaRef.collection('decisions');
            const ref = {
                tag, 
                query: collectionRef.orderBy('date', 'desc').limit(200),
            }
            return addListener(ref, {update: docs => setDocuments(docs)});
        }
    }, [orga, orgaRef]);

    return [documents];
}

export default useDecisions;