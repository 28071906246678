import React, {useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import {Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import RespondIcon from '@material-ui/icons/KeyboardReturn';
import Form from '../Form/Form';
import FormText from '../Form/FormText';
import Symbol from '../icons/Symbol';
import formatDistance from 'date-fns/formatDistance';
import usePosts from '../../base/usePosts';
import buttons from './buttons';
import Comment from './Comment';

const useStyles = makeStyles((theme) => ({
    post: {
        display: 'flex',
        color: theme.palette.softText,
    },
    right: {
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.palette.softText,
    },
    text: {
        color: theme.palette.primary.contrastText,
        paddingLeft: '20px',
    },
}));

const Post = ({role, post, color}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [comments, addComment] = usePosts(post.id);
    const [comment, setComment] = useState({text: ''});

    const openComment = () => {
        setOpen(!open);
    };

    const reply = (object) => {
        setComment({});
        setOpen(false);
        addComment({
            ...object
        })
    }

    return (
        <Grid container item xs={12} key={`item-${post.id}`} >
            <Grid item xs={10} className={classes.post}>
                <Tooltip title={`role ${post.author.name || 'unknown'} (${post.author.email})`}>
                    <span >
                        <Symbol
                            name={post.author.symbol} 
                            className={classes.symbol}
                            color={color}
                            size={24}
                        />&nbsp;
                    </span>
                </Tooltip>
                <Tooltip title={`${buttons[post.type].label2}`}>
                    <span style={{color: buttons[post.type].color}} >{buttons[post.type].icon}</span>
                </Tooltip>
                <Typography className={classes.text} >
                    {post.text}
                </Typography>
            </Grid>
            <Grid item xs={2} className={classes.right}>
                <IconButton size='small' onClick={openComment}>
                    <RespondIcon />
                </IconButton>
                <Typography variant='body2' noWrap>
                    {formatDistance(post.date, Date.now(), {includeSeconds: false})}
                </Typography>
            </Grid>
            { open ? 
                <Grid container item xs={12} >
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11} >
                    <Form object={comment} setObject={reply}>
                        <FormText fieldName='text' label='Your comment' width={8} />
                    </Form> 
                    </Grid>
                </Grid> : ''
            }
            {comments.map(comment =>
                <Comment key={`item-${comment.id}`} comment={comment} color={color} />
            )}
        </Grid>
    )
}

export default Post;