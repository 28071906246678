import React from 'react';

const Arrow = ({size}) => (
  <svg width={size | '20'} height={size | '20'} viewBox="0 0 300 300" >
        <polygon points="50,100 250,200 50,300"  fill="#c62828" >
            <animateMotion
                path="M 0 0 l 50 0 Z" dur="0.5s" repeatCount="indefinite" 
            />
        </polygon>
  </svg> 
);

export default Arrow;

