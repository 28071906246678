import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from "@material-ui/core/CssBaseline";
import Subscribe from '../Subscribe';
import getTheme from '../../theme';
import useCurrent from '../../base/useCurrent';
import { setTheme } from '../../globalState';
import YorgaRouter from './YorgaRouter';
import firebaseConfig from '../../base/firebaseConfig';

const App =  () => {
    const {user, orga, member, team, role, updateOrga, updateMember, updateUser, updateTeam} = useCurrent();
    const [darkMode, setDarkMode] = useState(true);
    const [localTheme, setLocalTheme] = useState(getTheme(true));
    const notLaunchedYet = firebaseConfig.projectId === 'yorga-prod';

    useEffect(() => {
        //console.log('App, user refreshed: ', user);
        const newMode = user && user.darkMode;
        setDarkMode(newMode);
    }, [user]);

    useEffect(() => {
        const primary = orga ? orga.primaryColor : undefined;
        const secondary = orga ? orga.secondaryColor : undefined;
        const newTheme = getTheme(darkMode, primary, secondary);
        setLocalTheme(newTheme);
        setTheme(newTheme);
    }, [orga, darkMode]);

    const switchMode = () => {
        const newMode = !darkMode;
        setDarkMode(newMode);
        // add storage of mode to user profile
        if (user) {
            //console.log('Writing the new mode '+newMode+' in users.darMode');
            updateUser({darkMode: newMode});
        }
    }

    return (
        <ThemeProvider theme={localTheme}>
            { notLaunchedYet ?
                <div>
                    <CssBaseline />
                    <Subscribe /> 
                </div> :
                <YorgaRouter 
                    user={user} orga={orga} member={member} team={team} role={role} 
                    updateOrga={updateOrga} updateMember={updateMember} updateUser={updateUser} updateTeam={updateTeam}
                    darkMode={darkMode} switchMode={switchMode} localTheme={localTheme}
                />
            }
        </ThemeProvider>
    );
}

export default App;
