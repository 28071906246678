import React, { useState } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import OpenIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/ArrowDropUp';
import SearchIcon from '@material-ui/icons/Search';
import useFilter from '../../hooks/useFilter';
import useDebounce from '../../hooks/useDebounce';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
    },
    open : {
        display: 'flex',
    },
    right: {
        flexGrow: '1',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(3),
          width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));
  
const Section = ({title, docs, fields, renderDoc, limit, startOpen}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(startOpen);
    const [value, setValue] = useState('');
    const filter = useDebounce(value, 1000);
    const filtered = useFilter(docs, fields, filter);

    const handleChange = event => {
        event.preventDefault();
        setValue(event.target.value);
    }

    return (
        <Paper className={classes.root}>
            <Grid container >
                <Grid item xs={open ? 4 : 12} >
                    <Button 
                        className={classes.open} 
                        onClick={() => setOpen(!open)} 
                        color='default'
                        display='inline'
                    >
                        {open ? <CloseIcon /> : <OpenIcon />}
                        {title}
                    </Button>
                </Grid>
                { open ?
                    <Grid item xs={4} className={classes.buttons} >
                        <span className={classes.right} />
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Search…"
                                value={value}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={handleChange}
                            />
                        </div>
                    </Grid> : ''
                }
            </Grid>
            { open && docs ? <>
                {filtered.map((doc, idx) => limit && idx >= limit ? '' : renderDoc(doc))}
                { limit && filtered.length > limit ? 
                    <p>there are {filtered.length - limit} more documents, refine your search</p> : ''
                }
            </> : ''}
        </Paper>
    );
}

export default Section;
