import React from 'react';
import ApproveIcon from '@material-ui/icons/ThumbUp';
import QuestionIcon from '@material-ui/icons/ContactSupport';
import PropositionIcon from '@material-ui/icons/EmojiObjects';
import ObjectionIcon from '@material-ui/icons/NotInterested';
import SmileyIcon from '@material-ui/icons/EmojiEmotions';

const buttons = {
    'question': {icon: <QuestionIcon />, label: 'I have a clarification question', label2: "'s question", color: '#09F'},
    'proposition': {icon: <PropositionIcon />, label: 'I have a suggestion', label2: "'s idea", color: '#EE0'},
    'approve': {icon: <ApproveIcon />, label: 'I approve the proposition', label2: "'s support", color: '#4E4'},
    'objection': {icon: <ObjectionIcon />, label: 'I have a valid objection', label2: "'s objection", color: '#F00'},
    'smiley': {icon: <SmileyIcon />, label: "Let's smile...", label2: "'s smile", color: '#EA0'},
}

export default buttons;