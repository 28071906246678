import { useEffect, useState } from 'react';
import { useGlobalState } from '../globalState';
import addListener from './addListener';
import getUpdate from './getUpdate';

// return the pending invitations for the current orga
// and a function to add new invitations.
const useOrgaInvitations = () => {
    const [base] = useGlobalState('base');
    const [user] = useGlobalState('currentUser');
    const [orga] = useGlobalState('currentOrga');
    const [documents, setDocuments] = useState(null);
    const orgaId = orga ? orga.id : null;

    useEffect(() => {
        if (orgaId) {
            const tag = `${orgaId}-invitations`;
            const collectionRef = base.collection('invitations');
            const ref = {
                tag, 
                query: collectionRef.where('orgaId', '==', orgaId).where('status', '==', 'pending'),
            }
            return addListener(ref, {update: docs => {setDocuments(docs)}});
        }
    }, [orgaId, base]);

    const addInvitation = email => {
        const invite = getUpdate({
            orgaId,
            orgaName: orga.name,
            date: Date.now(),
            destEmail: email,
            fromEmail: user.email,
            status: 'pending',
        });
        return base.collection('invitations').doc().set(invite, {merge: true});
    }

    // Delete an invitation
    const deleteInvitation = (invite) => {
        const update = getUpdate({status: 'canceled', active: false}, invite);
        return base.collection('invitations').doc(invite.id).set(update, {merge: true});
    }
    
    return [documents, addInvitation, deleteInvitation];
}

export default useOrgaInvitations;