import React from 'react';
import Form from '../Form/Form';
import FormText from '../Form/FormText';
import FormList from '../Form/FormList';

const Templateform = ({template, setTemplate}) => {

    return (
        <Form object={template} setObject={object => setTemplate(template, object)} >
            <FormText fieldName='name' label="Template's name"/>
            <FormText fieldName='description' label="Short description" width='60%' />
            <FormText fieldName='purpose' label="Template's purpose" width='100%' />
            <FormList fieldName='duties' label="Duty" width='95%' />
        </Form>
    );
}

export default Templateform;