const firebaseConfig = {
    apiKey: "AIzaSyCBvYFGZXTogzs8Mqpl32qf7ihQHBNMeBA",
    authDomain: "yorga-prod.firebaseapp.com",
    databaseURL: "https://yorga-prod.firebaseio.com",
    projectId: "yorga-prod",
    storageBucket: "yorga-prod.appspot.com",
    messagingSenderId: "541633476352",
    appId: "1:541633476352:web:df71cbbe6440c4b7c865e5",
    measurementId: "G-15LR5TR8NT"
};
  
export default firebaseConfig;