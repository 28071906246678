import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { useGlobalState } from '../../globalState';

const MyAvatar = () => {
    const [user] = useGlobalState('currentUser');
    const [member] = useGlobalState('currentMember');
    const doc = member || user|| {name: 'Vistitor', surname: '', initials: '?'};

    return ( 
        <Avatar name={doc.name} surname={doc.surname} src={doc.avatarUrl} >
            {doc.initials}
        </Avatar>
    )
}

export default MyAvatar;