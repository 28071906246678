import React from 'react';
import YorgaLogo from '../icons/YorgaLogo';

const Spinner = ({on}) => (
    <span 
        style={{
        padding: '10px',
    }}>  
        {on ? <YorgaLogo /> : '' }
    </span>
);

export default Spinner;