import { useEffect, useState } from 'react';
import { useGlobalState } from '../globalState';
import addListener from './addListener';
import getUpdate from './getUpdate';

const useTopics = () => {
    const [base] = useGlobalState('base');
    const [teamRef, setTeamRef] = useState(null);
    const [user] = useGlobalState('currentUser');
    const [role] = useGlobalState('currentRole');
    const [team] = useGlobalState('currentTeam');
    const [archives, setArchives] = useState([]);

    useEffect(() => {
        const orgaId = user ? user.defaultOrga : null;
        const teamId = team ? team.id : null;
        const newTeamRef = base && orgaId && teamId ? base.collection('organizations').doc(orgaId).collection('teams').doc(teamId) : null;
        if (newTeamRef) {
            setTeamRef(newTeamRef);
            //console.log('Listening to topics', teamRef);
            const tag = `${teamId}-topics`;
            const collectionRef = newTeamRef.collection('topics');
            const ref = {
                tag, 
                query: collectionRef.orderBy('createdOn', 'desc').limit(100),
            }
            return addListener(ref, {update: docs => {setArchives(docs)}});
        }
    }, [base, user, team]);

    const addTopic = (topic) => {
        if (teamRef && topic && role) {
            const topicRef = teamRef.collection(`topics`).doc();
            const batch = base.batch();
            batch.set(topicRef, {
                createdOn: Date.now(), 
                author: {name: role.name, symbol: role.symbol, email: user.email},
                ...topic
            });
            return batch.commit(); // return the promise
        }        
    }

    const updateTopic = (id, update) => {
        if (teamRef && id && update) {
            const topicRef = teamRef.collection(`topics`).doc(id);
            const batch = base.batch();
            batch.update(topicRef, update);
            return batch.commit(); // return the promise
        }        
    }

    const deleteTopic = (id) => {
        if (teamRef && id) {
            const topicRef = teamRef.collection(`topics`).doc(id);
            const batch = base.batch();
            batch.delete(topicRef);
            return batch.commit(); // return the promise
        }        
    }

    const archiveTopic = (topic) => {
        if (teamRef && role && topic) {
            const topicRef = teamRef.collection(`topics`).doc(topic.id);
            const archiveRef = teamRef.collection(`archives`).doc(topic.id);
            const batch = base.batch();
            batch.set(archiveRef, getUpdate({
                createdOn: Date.now(), 
                archivedBy: role.name,
                ...topic
            }));
            batch.delete(topicRef);
            return batch.commit(); // return the promise
        }        
    }

    return [archives, addTopic, updateTopic, deleteTopic, archiveTopic];
}

export default useTopics;