import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import useTeams from '../../base/useTeams';
/* eslint-disable no-use-before-define */
import Autocomplete from '@material-ui/lab/Autocomplete';
import ViewText from './ViewText';

const FormTeam = (props) => {
    const {setValue, fieldName, width, value, label, ...rest} = props; // must NOT send injected props to DOM
    const [teams] = useTeams();
    const [team, setTeam] = useState(null);

    useEffect( () => {
        if (value && teams && teams.length > 0) {
            const newTeam = teams.find(item => item.id === value);
            //console.log('FormMember refreshing member: ', value, newMember, members);
            setTeam(newTeam);
        }
    }, [value, teams]);

    const displayTeam = team => team ? `${team.name}` : '';

    const onChange = (event, newValue) => {
        event.preventDefault();
        if (newValue) {
            //console.log('FormMember onChange: ', newValue.id);
            setValue(newValue.id); // ?
        }
    }

    return (
        <>
            {team && setValue ?
                <Autocomplete
                    id="team-picker"
                    options={teams}
                    value={team}
                    onChange={onChange}
                    getOptionSelected={(option, value) => value ? option.id === value.id : false}
                    getOptionLabel={(option) => displayTeam(option)}
                    renderInput={(params) => <TextField {...params} label={label} variant="standard" />}
                    {...rest}
                /> :
                <ViewText label={label} text={team ? `${team.name}` : ''} />
            }
        </>
    );
}

export default FormTeam;