import React, { useState } from 'react';
import { useGlobalState } from '../../globalState';
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography, Fab, TextField } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import YorgaLogo from '../icons/YorgaLogo';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
    },
    buttons: {
        paddingTop: '20px',
        paddingBottom: '12px',
        paddingRight: '10px',
    },
    text: {
        color: theme.palette.primary.contrastText,
        paddingLeft: '20px',
    },
    title: {
        textAlign: 'center',
        padding: '10px',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    baseline: {
        display: 'inline',
        textAlign: 'center',
        paddingBottom: '20px',
    },
    logo: {
        position: 'relative',
        top: '5px',
    },
    fab: {
        margin: theme.spacing(1),
    },
    textField: {
        margin: theme.spacing(1),
        width: '50%',
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const Subscribe = () => {
    const [base] = useGlobalState('base');
    const [email, setEmail] = useState('');
    const [subscribed, setSubscribed] = useState(false);
    const classes = useStyles();

    const onChangeEmail = event => {
        setEmail(event.target.value.trim());
    };

    const onSubmit = event => {
        event.preventDefault();
        console.log('Submit ', email);
        base.collection('subscribers').doc(email).set({email, date: Date.now()})
            .then(() => {
                setSubscribed(true);
            })
            .catch(error => {console.log('Error in subscription: ', error)});
    }

    return (
        <Paper className={classes.root} >
            <Typography variant='h1' className={classes.title} >
                y<span className={classes.logo}><YorgaLogo size='60' begin='3s' repeatCount='1'/></span>rga
            </Typography>
            <Typography variant='h5' className={classes.title} >
                <i>Let's replace hierarchy with a smarter thing</i>
            </Typography>
            <div className={classes.baseline} >
                { subscribed ? 
                    <Typography className={classes.title} >
                        Thank you. We'll contact you in a few months, until then enjoy life !
                    </Typography> :
                    <form noValidate autoComplete="off" onSubmit={onSubmit}>
                        <Typography className={classes.title} >
                            Intrigued by what we're cooking ? Enter your email to get some news when we'll have more to tell.
                        </Typography>
                        <TextField
                            id="outlined-name"
                            label="email"
                            className={classes.textField}
                            value={email}
                            onChange={onChangeEmail}
                            margin="normal"
                            variant="outlined"
                        />
                        <Fab 
                            color="secondary" 
                            aria-label="envoyer" 
                            className={classes.fab}
                            disabled={
                                /* eslint-disable-next-line no-useless-escape*/
                                !( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) )
                            } 
                            type="submit"
                        >
                            <SendIcon />
                        </Fab>
                        <h1>&nbsp;</h1>
                    </form>
                }
            </div>
        </Paper>
    );
}

export default Subscribe;
