// @flow

import * as React from 'react';
import Link from '@material-ui/core/Link';

export default React.Node = (decoratedHref, decoratedText, key) => {
  return (
    <Link href={decoratedHref} key={key} color='inherit' variant='button'>
        {decoratedText}
    </Link>
  );
};