import React from 'react';
import TeamIcon from '@material-ui/icons/GroupWork';
import DirectoryIcon from '@material-ui/icons/People';
import DecisionIcon from '@material-ui/icons/Gavel';
import QuestionIcon from '@material-ui/icons/Help';
import TemplatesIcon from '@material-ui/icons/Description';
import CloseMenuIcon from '@material-ui/icons/ArrowForwardIos';
import MenuIcon from '@material-ui/icons/Menu';
import ChartIcon from '@material-ui/icons/BubbleChart';
import MyAvatar from './MyAvatar';
import OrgaAvatar from './OrgaAvatar';
import RoleIcon from './RoleIcon';
import TeamAvatar from '../Teams/TeamAvatar';

const Icon = (props) => (
    <React.Fragment>
        {{
            'account': <MyAvatar {...props} />,
            'myRole': <RoleIcon {...props} />,
            'myTeam': <TeamAvatar {...props} />,
            'orga': <OrgaAvatar {...props} />,
            'templates': <TemplatesIcon />,
            'team': <TeamIcon />,
            'closeMenu': <CloseMenuIcon />,
            'menu': <MenuIcon />,
            'directory': <DirectoryIcon />,
            'decision': <DecisionIcon />,
            'chart': <ChartIcon />
        }[props.name] || <QuestionIcon />}
    </React.Fragment>
);

export default Icon;