import { useEffect, useState } from 'react';
import { useGlobalState } from '../globalState';
import addListener from './addListener';

//const templateTypes = [
//    {type: 'role', default: {name: '', purpose: 'Role purpose', duties: ['First role duty']}},
//    {type: 'team', default: {name: '', purpose: 'Team purpose', duties: ['First team duty']}},
//    {type: 'policy', default: {name: '', from: '', description: '', contact: ''}}
//];


// Te
const useTemplates = () => {
    const [base] = useGlobalState('base');
    const [user] = useGlobalState('currentUser');
    const [documents, setDocuments] = useState([]);

    useEffect(() => {
        const tag = 'templates';
        const collectionRef = base.collection('templates');
        const ref = {
            tag, 
            query: collectionRef,
        }
        return addListener(ref, {update: docs => {setDocuments(docs)}});
    }, [base]);

    const addTemplate = (template) => {
        console.log('useTemplates addTemplate: ', template);
        if (user.authorName && base && template) {
            template.author = user.id;
            template.authorName = user.authorName || user.email;
            const templateRef = base.collection('templates').doc();
            return templateRef.set(template); // return the promise
        }        
    }

    const setTemplate = (template, update) => {
        console.log('useTemplates setTemplate: ', update);
        if (template && template.id && update) {
            const templateRef = base.collection('templates').doc(template.id);
            update.author = user.id;
            update.authorName = user.authorName || user.email;
            return templateRef.set(update, {merge: true}); // return the promise
        }        
    }

    const deleteTemplate = (template) => { 
        if (!template || !template.id || !documents) {
            return null;
        }
        const templateRef = base.collection('templates').doc(template.id);
        return templateRef.deleted(); // return the promise
    }

    return [documents, addTemplate, setTemplate, deleteTemplate];
}

export default useTemplates;