
const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}

const getInfoFromEmail = email => {
    const fullName = email.split('@')[0].replace('_', '.');
    const names = fullName.split('.').map(item => capitalize(item));
    const name = names[0];
    const lastName = names.length > 1 ? names[1] : '';
    const initials = names.reduce((accumulator, item) => `${accumulator}${item.charAt(0)}`, '');
    return {name, lastName, initials};
}

export default getInfoFromEmail;