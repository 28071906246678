import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const TemplateLine = ({template, onClick, selected}) => {

    return (
        <Typography >
            <Button onClick={onClick} variant={selected ? 'outlined' : 'text'}>
                <b>{template.name}</b> &nbsp;by&nbsp; <i>{template.authorName}</i>, &nbsp;{template.description}
            </Button>
        </Typography>
    );
}

export default TemplateLine;