import React from 'react';
import Section from '../Section';
import InviteForm from './InviteForm';
import OrgaSettings from './OrgaSettings';
import Chart from '../Chart';

const Organization = ({orga, updateOrga}) => {
    return (
        <>
            <Chart orga={orga} />
            { orga ?
                <>
                    <Section title={`Invite new members to ${orga.name}`} >
                        <InviteForm orga={orga}/>
                    </Section>
                    <OrgaSettings orga={orga} updateOrga={updateOrga}/>
                </> : ''
            }
        </>
    );
}

export default Organization;
