import React from 'react';
import TextField from '@material-ui/core/TextField';
import ViewIcon from './ViewIcon';

const urlRegex = new RegExp(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi);
const isValidUrl = s => s.match(urlRegex);


const FormIcon = (props) => {
    const {setValue, fieldName, width, value, icon, ...rest} = props; // must NOT send injected props to DOM

    const handleChange = (event) => {
        if (!setValue || !fieldName) {
            console.log('Values are not properly injected');
        }
        event.preventDefault();
        setValue(event.target.value);
    }

    return (
        <>
            {setValue ? 
                <TextField 
                    onChange={handleChange}
                    value={value}
                    style={{width: '100%', color: isValidUrl(value) ? 'inherit' : 'secondary'}}
                    {...rest} 
                /> :
                <ViewIcon label={props.label} icon={icon} url={value} />
            }
        </>
    );
}

export default FormIcon;