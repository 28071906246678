import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import {Grid, Tooltip, Typography } from '@material-ui/core';
import Symbol from '../icons/Symbol';
import formatDistance from 'date-fns/formatDistance';

const useStyles = makeStyles((theme) => ({
    post: {
        display: 'flex',
        color: theme.palette.softText,
    },
    right: {
        display: 'flex',
        justifyContent: 'space-between',
        color: theme.palette.softText,
    },
    text: {
        color: theme.palette.primary.contrastText,
        paddingLeft: '20px',
    },
}));

const Comment = ({comment, color}) => {
    const classes = useStyles();
    return (
        <Grid container item xs={12}  >
            <Grid item xs={1} className={classes.post}>
            </Grid>
            <Grid item xs={9} className={classes.post}>
                <Tooltip title={`role ${comment.author.name} (${comment.author.email})`}>
                    <span >
                        <Symbol
                            name={comment.author.symbol} 
                            className={classes.symbol}
                            color={color}
                            size={16}
                        />&nbsp;
                    </span>
                </Tooltip>
                <Typography variant='body2' className={classes.text} >
                    {comment.text}
                </Typography>
            </Grid>
            <Grid item xs={2} className={classes.right}>
                <span></span>
                <Typography variant='body2' noWrap>
                    {formatDistance(comment.date, Date.now(), {includeSeconds: false})}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Comment;