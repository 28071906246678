import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Form from '../Form/Form';
import FormText from '../Form/FormText';
import RoleIcon from '../NavBar/RoleIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
    chips: {
        padding: theme.spacing(1),
    },
    chip: {
        margin: theme.spacing(1),
    },
    spacer: {
        flex: 1,
        padding: theme.spacing(1),
    },
}));
  
// values is an array of item each of which must have at least an id and a name property
// active is the id of the active/selected item if any.
// selectMessage and deleteMessage, if any, are displaid in a modal window for confirmation 
// before the onSelect or onDelete callbacks are called.
const Chips = ({values, label, active, onSelect, selectMessage, onDelete, deleteMessage, onCreate, createMessage, avatar, isOutlined, iconColor}) => {
    const classes = useStyles();
    const [addOpen, setAddOpen] = useState(false);
    const [object, setObject] = useState({name: ''});
    const [modal, setModal] = useState({active: false, message: '', action: null});

    const handleSelect = (item) => {
        if (selectMessage) {
            setModal({active: true, message: selectMessage, action: () => {closeModal(); onSelect(item)}});
        } else {
            onSelect(item);
        }
    };

    const handleDelete = (item) => {
        if (deleteMessage) {
            setModal({active: true, message: deleteMessage, action: () => {closeModal(); onDelete(item)}});
        } else {
            onDelete(item);
        }
    }

    const handleCreate = (object) => {
        if (onCreate && object && object.name) {
            setAddOpen(false);
            setObject({name: ''});
            return onCreate(object.name);
        } else {
            return Promise.reject(Error('Failed in creation'));
        }
    }

    const closeModal = () => {
        setModal({active: false, message: '', action: null});
    }

    return (
        <div>
            <div  className={classes.root}>
                <span className={classes.chips}>
                    {values ? values.map( item => 
                        <Chip 
                            className={classes.chip}
                            key={item.id} 
                            color={active === item.id ? 'secondary' : 'default'}
                            onClick={onSelect ? () => {handleSelect(item)} : null}
                            onDelete={onDelete ? () => {handleDelete(item)} : null}
                            icon={item.symbol ? <RoleIcon role={item} shift='true' /> : null}
                            avatar={avatar ? avatar(item) : null}
                            label={label ? item[label] : item.name} 
                            variant={isOutlined && isOutlined(item) ? 'outlined' : 'default'}
                        />
                    ) : ''}
                </span>
                <span className={classes.spacer} />
                { onCreate ?
                    <IconButton 
                        color={addOpen ? 'secondary' : 'default'}
                        label='Create a new one'
                        size='small'
                        onClick={() => setAddOpen(!addOpen)}
                    >
                        {addOpen ? <CloseIcon /> : <AddIcon />}
                    </IconButton> : ''
                }
            </div>
            { addOpen ?
                <div>
                    <Form object={object} setObject={handleCreate} >
                        <FormText  
                            fieldName='name' 
                            label='name'
                            helperText={createMessage} 
                            width={6}
                        />
                    </Form>
                </div> : ''
            }
            <Dialog
                open={modal.active}
                aria-labelledby="alert-title"
                aria-describedby={modal.message}
            >
                <DialogTitle id="alert-dialog-title">Warning !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {modal.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => closeModal()} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={modal.action} color="secondary" variant="outlined" autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
} 

export default Chips;