import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import { useGlobalState } from '../../globalState';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import SendIcon from '@material-ui/icons/Send';
import YorgaLogo from '../icons/YorgaLogo';
import * as ROUTES from '../../routes'
import actionCodeSettings from './actionCodeSettings';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '77px',
        padding: '30px',
        textAlign: 'center',
        fontSize: '1.2em',
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    fab: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    textField: {
        margin: theme.spacing(1),
        width: '50%',
    },
    dense: {
      marginTop: theme.spacing(2),
    },
}));

const SignInCheck = () => {
    const [auth] = useGlobalState("auth")
    const [state, setState] = React.useState('pending');
    const [error, setError] = React.useState('');
    const classes = useStyles();
    
    useEffect(() => {
        console.log('SignInCheck activated');
        // Confirm the link is a sign-in with email link.
        if (auth.isSignInWithEmailLink(window.location.href)) {
            console.log('Provided a valid connection link');
            var email = window.localStorage.getItem('emailForSignIn');
            console.log(`Found a local storage email : ${email}`);
            if (!email) {
                // User opened the link on a different device. 
                email = window.prompt('Please provide your email for confirmation');
            }
            // The client SDK will parse the code from the link for you.
            auth.signInWithEmailLink(email, window.location.href)
                .then(function(result) {
                    // Clear email from storage.
                    console.log(`Authenticated user : ${result.user.email}`);
                    setState('done');
                    window.localStorage.removeItem('emailForSignIn');
                })
                .catch(function(error) {
                    console.log(`Authentication failed : ${error}`)
                    setError(error);
                    setState('error');
                })
        } else {
            setError("This is not a valid sign-in email link : "+window.location.href);
        }
    }, [auth]);
        
    return (
        <header className="App-header">
            <div className={classes.root} >
                <p>Vérification du lien...</p>
                { state === 'done' ? <Redirect to={ROUTES.LANDING} /> : '' }
                { state === 'pending' ? <YorgaLogo /> : '' }
                { state === 'error' ? <p>{error}</p> : '' }           
            </div>
        </header>
    );
}

const SignInForm = () => {
    const [auth] = useGlobalState('auth');
    const [email, setEmail] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const classes = useStyles();

    const onChangeEmail = event => {
        setEmail(event.target.value.trim());
    };

    const onSubmit = event => {
        event.preventDefault();
        setLoading(true);
        setMessage('');
        auth.sendSignInLinkToEmail(email, actionCodeSettings)
            .then(authUser => {
                console.log(`Link sent succesfully to ${email}`)
                // The link was successfully sent. Inform the user.
                setMessage(`A link was successfuly sent to ${email}, please check your inbox. 
                Beware : you must use the same web browser on the same device to ask for the link and to open it !`);
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem('emailForSignIn', email);
                setLoading(false);
            })
            .catch(error => {
                console.log('Attempt to send to '+email);
                setMessage(error.message);
                setLoading(false);
            });
    };

    return (
        <header className={classes.root}>
            <h1>Sign in / Log in</h1>
            <form noValidate autoComplete="off" onSubmit={onSubmit}>
                {loading ? 
                    <YorgaLogo /> :
                    <React.Fragment> 
                        {message ? message : 
                            <div>
                            <p>You must have a valid email address to log in. </p> 
                            <p>Enter it below and you'll receive an email with a secured link. </p>
                            <p>This link is personal and can only be used once.</p>
                            <TextField
                                id="outlined-name"
                                label="email"
                                className={classes.textField}
                                value={email}
                                onChange={onChangeEmail}
                                margin="normal"
                                variant="outlined"
                            />
                            <Fab 
                                color="secondary" 
                                aria-label="envoyer" 
                                className={classes.fab}
                                disabled={
                                    /* eslint-disable-next-line no-useless-escape*/
                                    !( /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) )
                                } 
                                type="submit"
                            >
                                <SendIcon />
                            </Fab>
                            </div>
                        }
                        <h1>&nbsp;</h1>
                    </React.Fragment>
                }
            </form>
        </header>
    );
};

export { SignInForm, SignInCheck };