import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import OpenIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/ArrowDropUp';


const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
    },
    open : {
        display: 'flex',
    },
    right: {
        flexGrow: '1',
    }
}));
  
const Section = ({title, children, startOpen}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(startOpen);
    return (
        <Paper className={classes.root}>
            { open ?
                <> 
                    <Button 
                        className={classes.open} 
                        onClick={() => setOpen(false)} 
                        color='default'
                        display='inline'
                    >
                        <CloseIcon />
                        {title}
                    </Button>
                    {React.Children.map(children, child => child)}
                </> : 
                <Button 
                    className={classes.open} 
                    onClick={() => setOpen(true)} 
                    color='default'
                >
                    <OpenIcon />
                    {title}
                </Button>
            }
        </Paper>
    );
}

export default Section;
