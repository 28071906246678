import React from 'react';
import Symbol from '../icons/Symbol';
import { useGlobalState } from '../../globalState';

const RoleIcon = ({role, shift}) => {
    const [theme] = useGlobalState('theme');
    const color = theme ? theme.palette.primary.contrastText : '#F00';
    
    return ( 
        <>
            { role ?
                <Symbol name={role.symbol || 'cross'} color={color} shift={shift} /> : ''
            }
        </>
    )
}

export default RoleIcon;