import { useEffect, useState } from 'react';
import { useGlobalState } from '../globalState';
import addListener from './addListener';

// usePosts create/use a subCollection to document with belongsTo id
// and then will sort them locally by chronoloical order
const usePosts = (belongsTo) => {
    const [base] = useGlobalState('base');
    const [user] = useGlobalState('currentUser');
    const [role] = useGlobalState('currentRole');
    const [posts, setPosts] = useState([]);
    const orgaId = user ? user.defaultOrga : null;
    const postsRef = orgaId ? base.collection('organizations').doc(orgaId).collection('posts') : null;

    useEffect(() => {
        if (postsRef) {
            //console.log(`Listening to  ${belongsTo} posts`);
            const tag = `${belongsTo}-posts`;
            const ref = {
                tag, 
                query: postsRef.where('belongsTo', '==', belongsTo).limit(1000),
            }
            return addListener(ref, {update: docs => {
                docs.sort((a, b) => a.date > b.date ? -1 : 1);
                setPosts(docs);
            }});
        }
    }, [postsRef, belongsTo]);

    const addPost = (post) => {
        if (postsRef && post && user && user.email) {
            const postRef = postsRef.doc();
            return postRef.set({
                date: Date.now(), 
                belongsTo,
                modifiedBy: user.email,
                author: {name: role.name, symbol: role.symbol, email: user.email},
                ...post
            });
        }        
    }

    const updatePost = (postId, update) => {
        if (postsRef && postId) {
            const postRef = postsRef.doc(postId);
            return postRef.set({
                updatedOn: Date.now(), 
                ...update
            }, {merge: true});
        }        
    }

    const deletePost = (postId) => {
        if (postsRef && postId) {
            const postRef = postsRef.doc(postId);
            return postRef.delete();
        }        
    }

    return [posts, addPost, updatePost, deletePost];
}

export default usePosts;