import React from 'react';
import { Grid, Tooltip, Link, Typography } from '@material-ui/core';

const ViewIcon = ({label, icon, url}) => (
    <Grid item xs={12}>
        <Tooltip title={label}>
            <Link href={url} color='inherit' target="_blank">
                <Typography variant='body2' >
                    {icon} {url}
                </Typography>
            </Link>
        </Tooltip>
    </Grid>
);

export default ViewIcon;