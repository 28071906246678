import React, { useState, useEffect } from 'react';
import { useGlobalState } from '../../globalState';
import useOrganizations from '../../base/useOrganizations';
//import useRoles from '../../base/useRoles';
//import useMembers from '../../base/useMembers';
import useTeams from '../../base/useTeams';
//import useDecisions from '../../base/useDecisions';
import Section from '../Section';
import { Grid, Typography, Box } from '@material-ui/core';
import IconOK from '@material-ui/icons/Check';
import IconKO from '@material-ui/icons/Clear';

const Test = ({title, test}) => (
    <Grid container justify="space-between">  
        <Typography inline='true' variant="body1" align="left">
            {title}:&nbsp;
        </Typography>
        <Typography inline='true' variant="body1" align="right">
            { test ? 
                <IconOK size='small'  color='action'/> : 
                <IconKO size='small' color='error'/>
            } 
        </Typography>
    </Grid>
);

const BaseCheck = () => {
    const [user] = useGlobalState('currentUser');
    const [orga] = useGlobalState('currentOrga');
    const [member] = useGlobalState('currentMember');
    const [team] = useGlobalState('currentTeam');
    const [role] = useGlobalState('currentRole');
    const [organizations] = useOrganizations();
    const [teams] = useTeams();
    //const [roles] = useRoles();
    //const [members] = useMembers();
    //const [decisions] = useDecisions();
    const [testUser, setTestUser] = useState(false);
    const [testUserVsOrga, setTestUserVsOrga] = useState(false);
    const [testOrga, setTestOrga] = useState(false);
    const [testUserVsMember, setTestUserVsMember] = useState(false);
    const [testMember, setTestMember] = useState(false);
    const [testMemberVsTeam, setTestMemberVsTeam] = useState(false);
    const [testTeam, setTestTeam] = useState(false);
    const [testMemberVsRole, setTestMemberVsRole] = useState(false);
    const [testRole, setTestRole] = useState(false);
    const [testOrganizations, setTestOrganizations] = useState(false);
    const [testTeams, setTestTeams] = useState(false);

    useEffect( () => {
        if (!user) {
            return;
        }
        if (user.id.length !== 28) {
            console.log('User id is wrong: ', user.id);
            return;
        }
        const unknownKeys = Object.keys(user).filter(key => !['id', 'email', 'defaultOrga', 'avatarUrl', 'invitation', 'darkMode', 'memberOf', 'name', 'lastName', 'initials', 'authorName'].includes(key));
        if (unknownKeys.length > 0) {
            console.log('User, unknown keys: ', unknownKeys);
            return;
        }
        /* eslint-disable-next-line no-useless-escape*/
        const validEmailAddress = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!validEmailAddress.test(String(user.email).toLowerCase())) {
            console.log('User, invalid email ', user.email);
            return
        }
        setTestUser(true);
    }, [user]);

    useEffect( () => {
        if (!orga || !user) {
            return;
        }
        if (user.defaultOrga !== orga.id) {
            console.log('User defaultOrga / Orga id ', user.defaultOrga, orga.id);
            return;
        }
        if (!user.memberOf || !user.memberOf.includes(orga.id)) {
            console.log('Orga id not in user memberOf ', user.memberOf, orga.id);
            return;
        }
        setTestUserVsOrga(true);
    }, [user, orga]);

    useEffect( () => {
        if (!orga) {
            return;
        }
        const unknownKeys = Object.keys(orga).filter(key => !['id', 'name', 'firstTeam', "logoUrl", "primaryColor", "secondaryColor", "active", "createdOn", "createdBy", "welcome", 'email', 'phone', 'modifiedBy'].includes(key));
        if (unknownKeys.length > 0) {
            console.log('Orga, unknown keys: ', unknownKeys);
            return;
        }
        setTestOrga(true);
    }, [orga]);

    useEffect( () => {
        if (!member || !user) {
            return;
        }
        if (member.id !== user.id) {
            console.log('User / Member invalid ids ', user.id, member.id);
            return;
        }
        if (member.mail !== user.mail) {
            console.log('User / Member invalid mail ', user.mail, member.mail);
            return;
        }
        setTestUserVsMember(true);
    }, [member, user]);

    useEffect( () => {
        if (!member) {
            return;
        }
        const unknownKeys = Object.keys(member).filter(key => !['id', 'email', 'defaultRole', 'defaultTeam', 'avatarUrl', 'invitation', 'teams', 'roles', 'name', "lastName", "initials", 'active', 'phone', 'address', 'modifiedBy'].includes(key));
        if (unknownKeys.length > 0) {
            console.log('Member, unknown keys: ', unknownKeys);
            return;
        }
        if (!member.roles || !member.roles.length || !member.teams || !member.teams.length) {
            console.log('Member no teams or roles arrays: ', member.teams, member.roles);
            return;
        }
        setTestMember(true);
    }, [member]);

    useEffect( () => {
        if (!team || !member) {
            return;
        }
        if (member.defaultTeam !== team.id) {
            console.log('User defaultOrga / Orga id ', member.defaultTeam, team.id);
            return;
        }
        if (!member.teams || !member.teams.includes(team.id)) {
            console.log('Team id not in member teams ', member.teams, team.id);
            return;
        }
        setTestMemberVsTeam(true);
    }, [member, team]);

    useEffect( () => {
        if (!team || !team.roles || !team.roles.length) {
            return;
        }
        const unknownKeys = Object.keys(team).filter(key => !['id', 'name', 'roles', "parent", "children", "duties", "purpose", "avatarUrl", "color", 'active', 'modifiedBy'].includes(key));
        if (unknownKeys.length > 0) {
            console.log('Team, unknown keys: ', unknownKeys);
            return;
        }
        setTestTeam(true);
    }, [team]);

    useEffect( () => {
        if (!member || !member.defaultTeam || !member.defaultRole || !role || !role.team) {
            return;
        }
        if (member.defaultRole !== role.id) {
            console.log('Member defaultRole and Role id: ', member.defaultRole, role.id);
            return;
        }
        if (member.defaultTeam !== role.team) {
            console.log('Member defaultTeam and Role team: ', member.defaultTeam, role.team);
            return;
        }
        if (member.id !== role.holder) {
            console.log('Member id and Role holder: ', member.id, role.holder);
            return;
        }
        setTestMemberVsRole(true);
    }, [member, role]);

    useEffect(() => {
        if (!role || !role.duties || !role.duties.length) {
            return;
        }
        const unknownKeys = Object.keys(role).filter(key => !["id", "name", "purpose", "symbol", "holder", "duties", "team", 'active', 'modifiedBy'].includes(key));
        if (unknownKeys.length > 0) {
            console.log('Role, unknown keys: ', unknownKeys);
            return;
        }
        setTestRole(true);
    }, [role]);

    useEffect(() => {
        if (!user || !organizations || organizations.length < 1) {
            return;
        }
        if (organizations.filter(org => org.id === user.defaultOrga).length !== 1 ) {
            console.log('Organizations, no match with user.defaultOrga: ', user.defaultOrga);
            return;
        }
        setTestOrganizations(true);
    }, [user, organizations]);

    useEffect(() => {
        if (!teams || teams.length < 1 || !orga) {
            return;
        }
        if (teams.filter(item => item.id === orga.firstTeam).length !== 1 ) {
            console.log('Teams, no match with orga.firstTeam: ', teams, orga.firstTeam);
            return;
        }
        setTestTeams(true);
    }, [teams, orga]);

    return (
        <Section title='Base Checker' startOpen>
            <div><Box fontStyle="italic">
                Check consistency of the collections available to user
            </Box></div>
            <p></p>
            <Test title='user consistency' test={ testUser } />
            <Test title='user vs orga consistency' test={ testUserVsOrga } />
            <Test title='orga consistency' test={ testOrga } />
            <Test title='user vs member consistency' test={ testUserVsMember } />
            <Test title='member consistency' test={ testMember } />
            <Test title='member vs team consistency' test={ testMemberVsTeam } />
            <Test title='team consistency' test={ testTeam } />
            <Test title='member vs role consistency' test={ testMemberVsRole } />
            <Test title='role consistency' test={ testRole } />
            <Test title='organizations consistency' test={ testOrganizations } />
            <Test title='teams consistency' test={ testTeams } />
        </Section>
    )
}

export default BaseCheck;
