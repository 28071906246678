import React from 'react';
import Chips from '../Chips';
import useOrganizations from '../../base/useOrganizations';
import Section from '../Section';
import InvitationManager from './InvitationManager';
import { useGlobalState } from '../../globalState';

const OrganizationPicker = ({user, updateUser}) => {
    const [orga] = useGlobalState('currentOrga');
    const [organizations, createOrganization, leaveOrganization] = useOrganizations();

    const handleChange = (org) => {
        if (user && org && user.defaultOrga !== org.id) {
            updateUser({defaultOrga: org.id});
        }
    };

    const handleCreate = (name) => {
        console.log('creating organization...', name);
        if (name) {
            createOrganization(name)
                .then(() => {
                    console.log('Created org: '+name);
                }).catch( error => {
                    console.log('Error creating org:', error);
                });
        }
    }

    const handleLeaveOrganization = (org) => {
        if (org && org.id) {
            leaveOrganization(org.id);
        }
    }

    return (
        <Section title="My organizations" startOpen={orga ? false : true}>
            <Chips
                values={organizations}
                active={orga ? orga.id : null}
                onSelect={handleChange}
                onDelete={handleLeaveOrganization}
                deleteMessage={`Do you really want to leave ${orga ? orga.name : ''} ? By confirming you will quit all your roles, and won't be able to come back unless invited again.`}
                onCreate={handleCreate}
                createMessage={`Creating a new, empty, organization with you as the leader of its first team`}
            /> 
            <InvitationManager />
        </Section>
    )
} 

export default OrganizationPicker;