import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../Spinner';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    grid: {
        padding: theme.spacing(2),
    },
    buttons: {
        display: 'flex',
        alignItems: 'right',
        padding: '10px',
    },
    cancel: {
        marginRight: '20px',
    },
    spacer: {
        flex: 1,
    },
}));

// Display a form with Form children components as the fields, using 'object' fields 
// as default values, and calling setObject with new values when submitted.
// Note the children MUST have a 'fieldName' property with the name of the field
// they should modify in the object as its value. they must use the automatically
// provided 'value' prop as field value, and should modify it with the automatically
// provided 'setValue' function in their handleChange function.

const Form = ({object, setObject, children}) => {
    const [pendingObject, setPendingObject] = useState(object);
    const [touched, setTouched] = useState(false);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    useEffect(() =>{
        //console.log('Form refreshing pendingObject', pendingObject);
        setPendingObject(object);
    }, [object])

    const setField = (fieldName, fieldValue) => {
        //console.log('Form, setField: ', fieldName, fieldValue);
        if (pendingObject[fieldName] !== fieldValue) {
            const newObject = {...pendingObject};
            newObject[fieldName] = fieldValue;
            setPendingObject(newObject);
            setTouched(true);
        }
    }

    const submit = event => {
        event.preventDefault();
        //console.log('Form submitted');
        //console.log(pendingObject);
        setTouched(false);
        const prom = setObject(pendingObject);
        if (prom && prom.then) { // provided function returned a promise
            setLoading(true);
            prom
                .then(() => setLoading(false))
                .catch(error => {
                    console.log(`Error ${error} in submitting object`, object);
                    setLoading(false);
                });
        }
    }

    const cancel = () => {
        //console.log('Form canceled: ', object, pendingObject);
        setPendingObject({...object});
        setTouched(false);
    }

    return (
        <form onSubmit={submit} noValidate autoComplete="off"> { pendingObject ?
            <Grid container className={classes.root} > 
                { loading ? <Spinner /> : ''}
                {React.Children.map(children, child => 
                    <Grid item xs={child.props.width || 3} className={classes.grid}> 
                        {child.props.fieldName ? 
                            React.cloneElement(child, {
                                value: pendingObject[child.props.fieldName] || '',
                                setValue: newValue => setField(child.props.fieldName, newValue),
                            }) : child
                        } 
                    </Grid>
                )}
                { touched ? 
                    <Grid item xs={2} className={classes.buttons}>
                        <Button onClick={cancel} className={classes.cancel} size='small'>
                            Cancel
                        </Button> 
                        <Button type='submit' color='primary' variant='contained' size='small'>
                            Submit
                        </Button> 
                    </Grid> : ''
                }
            </Grid> : ''
        } </form>
    );
}

export default Form;
