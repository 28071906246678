import firebase from 'firebase/app';
import { useEffect, useState } from 'react';
import { useGlobalState } from '../globalState';
import addListener from './addListener';
import getUpdate from './getUpdate';

// return the role documents from {defaultOrgaId}-roles and a
// function to add a new one. 
const useRoles = () => {
    const [base] = useGlobalState('base');
    const [user] = useGlobalState('currentUser');
    const [member] = useGlobalState('currentMember');
    const [team] = useGlobalState('currentTeam');
    const [documents, setDocuments] = useState([]);
    const orgaId = user ? user.defaultOrga : null;
    const teamId = member ? member.defaultTeam : null;
    const orgaRef = base && orgaId ? base.collection('organizations').doc(orgaId) : '';

    useEffect(() => {
        if (orgaId) {
            const tag = `${orgaId}-roles`;
            const collectionRef = orgaRef.collection('roles');
            const ref = {
                tag, 
                query: collectionRef.where('active', '==', true),
            }
            return addListener(ref, {update: docs => {setDocuments(docs)}});
        } else {
            setDocuments([]); // when logout
        }
    }, [orgaId, orgaRef]);

    // To create a new role in the current team with the creator as its first holder
    const addRole = name => {
        if (!orgaId || !teamId) {
            console.log('No current orga or current team');
            return null;
        }
        const teamRolesSearch = documents.filter(doc => doc.team === teamId);
        const roleNames = teamRolesSearch.map(doc => doc.name);
        if (roleNames.includes(name)) {
            console.log('Name already used in team');
            return null;
        }
        const roleRef = orgaRef.collection(`roles`).doc();
        const teamRef = orgaRef.collection(`teams`).doc(teamId);
        team.roles.push(roleRef.id);
        const teamUpdate = {roles: team.roles};
        const newRole = getUpdate({
            name,
            team: teamId,
            holder: member.id, // the creator of a role is its first holder
            symbol: 'new',
            purpose: '',
            duties: [],
            active: true,
            modifiedBy: user.email,
        });
        const batch = base.batch();
        batch.update(teamRef, teamUpdate);
        batch.set(roleRef, newRole);
        return batch.commit(); // return the promise
    }

    const setRole = (id, update) => {
        if (id && update) {
            const roleRef = orgaRef.collection(`roles`).doc(id);
            const role = documents.find(doc => doc.id === id);
            if (!role) {
                return null;
            }
            const batch = base.batch();
            // members.teams and members.roles modifications
            if (update.holder && update.holder !== role.holder) {
                //console.log('useRoles setRole modifying holder...', update.holder);
                const formerMemberRef = orgaRef.collection(`members`).doc(role.holder);
                const newMemberRef = orgaRef.collection(`members`).doc(update.holder);
                const otherRoleInTeam = documents.find(doc => 
                    doc.holder === role.holder && doc.id !== role.id && doc.team === role.team
                );
                if (!otherRoleInTeam) {
                    //console.log('... had no other role in team, member removed from team');
                    const otherRoleAnyTeam = documents.find(doc => 
                        doc.holder === role.holder && doc.id !== role.id
                    );
                    batch.update(formerMemberRef, getUpdate({
                        defaultRole: otherRoleAnyTeam ? otherRoleAnyTeam.id : null,
                        defaultTeam: otherRoleAnyTeam ? otherRoleAnyTeam.team : null,
                        teams: firebase.firestore.FieldValue.arrayRemove(role.team),
                        roles: firebase.firestore.FieldValue.arrayRemove(role.id),
                    }));
                } else {
                    //console.log('Had another role in team, member left in team');
                    batch.update(formerMemberRef, getUpdate({
                        defaultRole: otherRoleInTeam.id,
                        roles: firebase.firestore.FieldValue.arrayRemove(role.id),
                    }));
                }
                //console.log('adding the role to new member teams & roles');
                batch.update(newMemberRef, getUpdate({
                    defaultTeam: role.team,
                    defaultRole: role.id,
                    teams: firebase.firestore.FieldValue.arrayUnion(update.team || role.team),
                    roles: firebase.firestore.FieldValue.arrayUnion(role.id),
                }));
            }
            // role update
            //console.log('role update ', update);
            batch.update(roleRef, getUpdate(update, role));
            return batch.commit(); // return the promise
        }        
        return Promise.reject(new Error('failed by lack of id or update'));
    }

    const deleteRole = (id) => {
        if (id && documents) {
            const role = documents.find(doc => doc.id === id);
            if (!role) {
                console.log(`Can't find role ${id}, can't delete it`);
                return null; 
            }
            // is there another role in the same team for the same member ?
            const otherRoleInTeam = documents.find(doc => doc.holder === role.holder && doc.id !== role.id && doc.team === role.team);
            const batch = base.batch();
            const roleRef = orgaRef.collection(`roles`).doc(id);
            const memberRef = orgaRef.collection(`members`).doc(role.holder);
            const teamRef = orgaRef.collection(`teams`).doc(role.team);
            if (!otherRoleInTeam) {
                batch.update(memberRef, getUpdate({teams: firebase.firestore.FieldValue.arrayRemove(role.team)}));
            }
            batch.update(memberRef, getUpdate({roles: firebase.firestore.FieldValue.arrayRemove(id)}));
            batch.update(teamRef, getUpdate({roles: firebase.firestore.FieldValue.arrayRemove(id)}, team));
            batch.update(roleRef, getUpdate({active: false}, role));
            return batch.commit(); // return the promise
        }        
    }

    return [documents, addRole, setRole, deleteRole];
}

export default useRoles;