import React from 'react';
import SectionSearch from '../Section/SectionSearch';
import useMembers from '../../base/useMembers';
import useTeams from '../../base/useTeams';
import useRoles from '../../base/useRoles';
import Member from './Member';
import Team from './Team';
import Role from './Role';

const Directory = () => {
    const [members] = useMembers();
    const [teams] = useTeams();
    const [roles] = useRoles();

    return (
        <>
            <SectionSearch 
                title='Members directory' 
                docs={members} 
                fields={['name', 'lasName', 'email', 'address', 'phone']} 
                renderDoc={doc => 
                    <Member
                        member={doc} 
                        key={doc.id} 
                        teams={teams}
                        roles={roles}
                    />            
                }
                limit={20}
            />
            <SectionSearch 
                title='Teams directory' 
                docs={teams} 
                fields={['name', 'purpose', 'duties']} 
                renderDoc={doc => 
                    <Team
                        team={doc} 
                        key={doc.id} 
                        roles={roles}
                        members={members}
                    />            
                }
                limit={20}
            />
            <SectionSearch 
                title='Roles directory' 
                docs={roles} 
                fields={['name', 'purpose', 'duties']} 
                renderDoc={doc => 
                    <Role
                        role={doc} 
                        key={doc.id} 
                        teams={teams}
                        members={members}
                    />            
                }
                limit={20}
            />
        </>
    );
}

export default Directory;
