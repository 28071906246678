// centralizes firestore collection subscriptions to avoid useless reads,
// and make the subscription persistent for 5 minutes to avoid new requests
// when navigating the service.

// Should be called only from base hooks (like use{Document} or use{Collection}).

let collections = [];

const traceIsOn = false;

const trace = (text, object) => {
    if (traceIsOn) {
        object ? console.log(text, object) : console.log(text);
    }
}

const addCollection = (ref, firstListener) => {
    trace('Adding subscription to '+ref.tag);
    const newCollection = {
        tag: ref.tag, 
        query: ref.query, 
        docs: [], 
        listeners: [firstListener]
    };
    collections.push(newCollection);

    newCollection.unsubscribe = ref.query.onSnapshot(snapshot => {
        trace('... received a new snapshot for '+ref.tag+' with '+snapshot.size+' docs.');
        // updating the collections listeners
        newCollection.docs = snapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
        newCollection.listeners.forEach(listener => {
            if (listener.id) {
                if (snapshot.size === 0) {
                    trace('No doc found for '+listener.id+' from collection '+ref.tag);
                    listener.update(null); // No doc found
                } else {
                    snapshot.docChanges().forEach(change => {
                        if (listener.id === change.doc.id) {
                            trace('Updating doc '+listener.id+' from collection '+ref.tag);
                            listener.update(change.doc.data());
                        }
                    });
                }
            } else {
                trace('Updating the whole collection for '+ref.tag);
                listener.update(newCollection.docs);
            }
        });
    }, error => {
        trace(`Query listnener for ${ref.tag} failed, it will be ended: ${error}`);
        removeCollection(ref.tag);
    });
    return newCollection;
}

const removeCollection = (tag) => {
    trace('Removing subscription to '+tag);
    collections = collections.filter(collection => {
        if (collection.tag !== tag) {
            return true;
        } else {
            collection.unsubscribe();
            return false;
        }
    });
}

// listeners must be of the form : {update: the callback, id (optional) }
// when there's an id provided, it means it's a single document listener
// otherwise the whole collection is listened to.
const addListener = (ref, listener) => {
    trace(`Adding listener for ${ref.tag}, ${listener.id || 'all documents'}`);
    let collection = collections.find(col => col.tag === ref.tag);

    if (collection) {
        trace('Collection already listened to');
        if (collection.docs && collection)
        if (collection.timerId) {
            trace('Resetting timer on collection...')
            clearTimeout(collection.timerId)
            collection.timerId = null;
        }
        collection.listeners.push(listener);
        trace('Adding listener to '+ref.tag+', now there are '+collection.listeners.length+' listeners.');
        if (listener.id) { // listening to a single doc
            trace('Looking for an existing specific doc for '+listener.id, collection);
            const value = collection.docs.find(doc => doc.id === listener.id);
            if (value) { 
                trace('... found it: ', value);
               listener.update(value);
            } else {
                trace('Document not found, setting to empty object.');
                listener.update({});
            }
        } else { // listening to the whole collection
            trace('Returning the existing docs:', collection.docs)
            listener.update(collection.docs);
        }
    } else {
        trace('New query to collection to listen to');
        collection = addCollection(ref, listener);
    }
    // return the unsubscribe function
    return () => {
        if (collection) {
            collection.listeners = collection.listeners.filter(lsnr => lsnr !== listener);
            if (collection.listeners.length === 0) { // it was the last listener
                trace('Last listener removed on '+collection.tag+', starting time...');
                collection.timerId = setTimeout(() => {
                    removeCollection(ref.tag);
                }, 300000); // waiting 5 minutes before removing the collection to limit useless read operations
            }
        }
    }
}

export default addListener;