import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import useMembers from '../../base/useMembers';
/* eslint-disable no-use-before-define */
import Autocomplete from '@material-ui/lab/Autocomplete';
import ViewText from './ViewText';

const FormMember = (props) => {
    const {setValue, fieldName, width, value, label, ...rest} = props; // must NOT send injected props to DOM
    const [members] = useMembers();
    const [member, setMember] = useState(null);

    useEffect( () => {
        if (value && members && members.length > 0) {
            const newMember = members.find(item => item.id === value);
            //console.log('FormMember refreshing member: ', value, newMember, members);
            setMember(newMember);
        }
    }, [value, members]);

    const displayMember = member => member ? `${member.name} ${member.lastName} (${member.email})` : '';

    const onChange = (event, newValue) => {
        event.preventDefault();
        if (newValue) {
            //console.log('FormMember onChange: ', newValue.id);
            setValue(newValue.id); // ?
        }
    }

    return (
        <>
            {member && setValue ?
                <Autocomplete
                    id="member-picker"
                    options={members}
                    value={member}
                    onChange={onChange}
                    getOptionSelected={(option, value) => value ? option.id === value.id : false}
                    getOptionLabel={(option) => displayMember(option)}
                    renderInput={(params) => <TextField {...params} label={label} variant="standard" />}
                    {...rest}
                /> :
                <ViewText label={label} text={member ? `${member.name} ${member.lastName}` : ''} small={member ? ` (${member.email})` : ''} />
            }
        </>
    );
}

export default FormMember;