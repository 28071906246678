// The Material UI palette for Yorga Brand color scheme - Yves Christol
import { createMuiTheme } from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';

export const getColor = (color, dark) => {
    //console.log('getColor ', color);
    return colors[color.hue][dark ? color.darkShade : color.lightShade];
}

const defaultPrimary = {hue: 'teal', darkShade: 800, lightShade: 400};
const defaultSecondary = {hue: 'green', darkShade: 400, lightShade: 400};

function getTheme(dark = true, primary = defaultPrimary, secondary = defaultSecondary) {
    return createMuiTheme( {
        fontFamily: "Roboto",
        spacing: 4,
        palette: {
            type: dark ? 'dark' : 'light',
            softText: dark ? '#bbb' : '#888',
            background: {
                default: dark ? '#222' : '#ddd',
            },
            primary: {
                main: getColor(primary, dark),
                active: getColor(primary, !dark),
                contrastText: dark ? '#fff' : '#000',
            },
            secondary: {
                main: getColor(secondary, dark),
                active: getColor(secondary, !dark),
                contrastText: dark ? '#fff' : '#000',
            },
            // Used by `getContrastText()` to maximize the contrast between
            // the background and the text.
            contrastThreshold: 3,
            // Used by the functions below to shift a color's luminance by approximately
            // two indexes within its tonal palette.
            // E.g., shift from Red 500 to Red 300 or Red 700.
            tonalOffset: 0.2,   
        },
        typography: {
            button: {
                textTransform: 'none'
            }
        },
    })
}

export default getTheme;