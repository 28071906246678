import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Delete';
import ViewText from './ViewText';

const useStyles = makeStyles((theme) => ({
    button: {
        verticalAlign: 'bottom',
    },
    grid: {
        padding: theme.spacing(2),
    },
}));

const FormList = (props) => {
    const {setValue, fieldName, width, value, label, ...rest} = props; // must NOT send injected props to DOM
    const classes = useStyles();
    const localValue = value || [''];

    const handleChange = (event, idx) => {
        if (setValue && fieldName && value) {
            event.preventDefault();
            const newValue = [...value];
            newValue[idx] = event.target.value;
            setValue(newValue);
        } else {
            console.log('Values are not properly injected');
        }
    }

    const handleRemove = (event, idx) => {
        if (setValue && fieldName && value) {
            event.preventDefault();
            const newValue = value.slice(0, idx).concat(value.slice(idx + 1, value.length));
            setValue(newValue);
        } else {
            console.log('Values are not properly injected');
        }
    }

    const handleAdd = (event) => {
        if (setValue && fieldName) {
            event.preventDefault();
            const newValue = [...localValue];
            newValue.push('');
            setValue(newValue);
        } else {
            console.log('Values are not properly injected');
        }
    }

    return (
        <> 
            {localValue.map( (item, idx) => 
                <span key={`span${idx}`} className={classes.grid} >
                    { setValue ? 
                        <>
                            <TextField 
                                key={`text${idx}`}
                                onChange={event => handleChange(event, idx)}
                                value={item}
                                label={`${label} n°${idx+1}`}
                                style={{width: '90%'}}
                                {...rest} 
                            />
                            <IconButton 
                                className={classes.button}
                                key={`button${idx}`} 
                                onClick={event => handleRemove(event, idx)} 
                                size='small'
                            >
                                <RemoveIcon key={`icon${idx}`}/>
                            </IconButton> 
                        </> :
                        <ViewText label={`${label} n°${idx+1}`} text={item} />
                    }
                </span>
            )}
            { setValue ? 
                <IconButton onClick={handleAdd} size='small' className={classes.button} >
                    <AddIcon />
                </IconButton> : ''
            }
        </>
    );
}

export default FormList;
