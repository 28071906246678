import React from 'react';
import TextField from '@material-ui/core/TextField';
import ViewText from './ViewText';
import format from 'date-fns/format';

const FormDate = (props) => {
    const {setValue, fieldName, width, value, label, ...rest} = props; // must NOT send injected props to DOM

    const handleChange = (event) => {
        if (!setValue || !fieldName) {
            console.log('Values are not properly injected');
        }
        event.preventDefault();
        setValue(event.target.value);
    }

    return (
        <>
            {setValue ? 
                <TextField 
                    onChange={handleChange}
                    value={format(value, "yy-MM-dd' 'HH:mm")}
                    style={{width: '100%'}}
                    {...rest} 
                /> :
                <ViewText label={label} text={format(value, "yy-MM-dd' 'HH:mm")} />
            }
        </>
    );
}

export default FormDate;
