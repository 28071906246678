import React from 'react';

const YorgaLogo = ({size, begin, repeatCount}) => (
  <svg width={size | '20'} height={size | '20'} viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg" >
    <circle cx="150" cy="150" r="150"  fill="#c62828" />
    <circle cx="150" cy="150" r="50"  fill="#FFF">
      <animateMotion
        path="M 0 0 l 50 -100 L 0 0 l 50 100 L 0 0 l -50 100 L 0 0 l -50 -100 Z" 
        dur="2s"       
        begin={begin || "0s"}
        repeatCount={repeatCount || "indefinite"}
      />
    </circle>
    <circle cx="50" cy="150" r="50"  fill="#FFF">
      <animateTransform 
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="0 150 150"
        to="360 150 150"
        dur="2s"
        begin={begin || "0s"}
        repeatCount={repeatCount || "indefinite"}
      />
    </circle>
    <circle cx="250" cy="150" r="50"  fill="#FFF" >
      <animateTransform 
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="0 150 150"
        to="360 150 150"
        dur="2s"
        begin={begin || "0s"}
        repeatCount={repeatCount || "indefinite"}
      />
    </circle>
  </svg> 
);

export default YorgaLogo;