import { useEffect, useState } from 'react';
import { useGlobalState, setCurrentRole, setCurrentTeam, getGlobalState } from '../globalState';
import addListener from './addListener';
import getUpdate from './getUpdate';
import getInfoFromEmail from './getInfoFromEmail';

// return the pending invitations for the current user
const useMyInvitations = () => {
    const [base] = useGlobalState('base');
    const [user] = useGlobalState('currentUser');
    const [documents, setDocuments] = useState(null);
    const email = user ? user.email : null;

    useEffect(() => {
        if (email) {
            const tag = `${email}-invitations`;
            const collectionRef = base.collection('invitations');
            const ref = {
                tag, 
                query: collectionRef.where('destEmail', '==', email).where('status', '==', 'pending'),
            }
            return addListener(ref, {update: docs => {setDocuments(docs)}});
        }
    }, [email, base]);

    // Reject an invitation
    const rejectInvitation = (invite) => {
        const role = getGlobalState('currentRole');
        const team = getGlobalState('currentTeam');
        setCurrentRole({name: 'guest'});
        setCurrentTeam({name: 'Organization'});
        const prom = base.collection('invitations').doc(invite.id).set(getUpdate(
            {status: 'rejected', active: false}, invite
        ), {merge: true});
        setCurrentRole(role);
        setCurrentTeam(team);
        return prom;
    }

    // The only member you can add to the current orga is yourself !
    const acceptInvitation = (invite) => {
        if (user && invite && (!user.memberOf || !(invite.orgaId in user.memberOf)) ) {
            setCurrentRole({name: 'New member'});
            setCurrentTeam({name: 'Organization'});
            // A) set up references
            const orgaRef = base.collection('organizations').doc(invite.orgaId);
            const memberRef = orgaRef.collection(`members`).doc(user.id);
            const userRef = base.collection('users').doc(user.id);
            const invitationsRef = base.collection('invitations').doc(invite.id);
            // B) build updates
            const newMemberOf = user.memberOf || [];
            newMemberOf.push(invite.orgaId);
            const memberInfo = getInfoFromEmail(user.email);
            const newMember = getUpdate({
                email: user.email,
                name: user.name || memberInfo.name,
                lastName: user.lastName || memberInfo.lastName,
                initials: user.initials || memberInfo.initials,
                active: true,
                roles: [],
                teams: [],
                defaultTeam: null,
                defaultRole: null,
                invitation: invite.id, // to pass firestore.rules
            });
            const userUpdate = {
                memberOf: newMemberOf, 
                //defaultOrga: invite.orgaId,
                invitation: invite.id, // to pass firestore.rules
            };
            const inviteUpdate = getUpdate({status: 'accepted', active: false}, invite);
            // C) update base in a batch
            const batch = base.batch();
            batch.set(userRef, userUpdate, {merge: true});
            batch.set(memberRef, newMember);
            batch.set(invitationsRef, inviteUpdate, {merge: true});
            batch.commit().then(() => {
                console.log('Invitation registered, great !');
            }).catch(error => {
                console.log('This is the detailed error:', error);
            })
            //return batch.commit(); // return the promise
        }      
    }  

    return [documents, acceptInvitation, rejectInvitation];
}

export default useMyInvitations;