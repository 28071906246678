
import React, { useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import useTeams from '../../base/useTeams';
import { getColor } from '../../theme';

const ChartView = ({orga}) => {
    const [teams] = useTeams();

    useEffect(() => {
        if (!orga || !teams || teams.length === 0) {
            return;
        }

        let chart = am4core.create("chartdiv", am4plugins_forceDirected.ForceDirectedTree);
        let series = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries());

        function getChildren(teamId) {
            const team = teams.find(t => t.id === teamId);
            if (!team) {
                return {name: 'error', value: 3, color: '#F00'};
            }
            return ({
                name: team.name || '?',
                id: team.id || 'unknown',
                color: team.color ? getColor(team.color, true) : '#777',
                value: team.roles ? team.roles.length : 0,
                children: team.children ? team.children.map(tId => getChildren(tId)) : undefined,
            })
        };

        series.data = [getChildren(orga.firstTeam)];
          
        // Set up data fields
        series.dataFields.value = "value";
        series.dataFields.name = "name";
        series.dataFields.id = "id";
        series.dataFields.color = "color";
        series.dataFields.children = "children";
        
        // Add labels
        series.nodes.template.label.text = "{name}";
        series.fontSize = 10;
        series.minRadius = 15;
        series.maxRadius = 40;
        
        series.centerStrength = 0.5;
        return () => chart.dispose();
    }, [teams, orga]);

    return (
        <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
    );
}

export default ChartView;