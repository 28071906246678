import React from 'react';
import Section from '../Section';
import ChartView from './ChartView';

const Chart = ({orga}) => {
    return (
        <>
            { orga ?
                <>
                    <Section title={`Global view of ${orga.name}`} startOpen>
                        <ChartView orga={orga} />
                    </Section>
                </> : ''
            }
        </>
    );
}

export default Chart;