import React, { useState, useEffect } from 'react';
import useTeams from '../../base/useTeams';
import Section from '../Section';
import Chips from '../Chips';
import TeamInfo from './TeamInfo';

const Children = ({team}) => {
    const [teams, addTeam, setTeam, deleteTeam] = useTeams();
    const [activeChild, setActiveChild] = useState(null);
    const childrenIds = team ? team.children : null;
    const [childrenTeams, setChildrenTeams] = useState([]);

    useEffect(() => {
        if (childrenIds && teams && teams.length > 0) {
            const newChildren = teams.filter(item => 
                childrenIds.includes(item.id)
            );
            //console.log('Children, refreshing children: ', newChildren);
            setChildrenTeams(newChildren);
        };
    }, [teams, childrenIds]);

    const handleSelect = val => {
        setActiveChild(!activeChild || val.id !== activeChild.id ? val : null);
    }

    const handleCreate = name => {
        //console.log('Creating child team, tested:', name);
        if (name && !childrenTeams.find(c => c.name === name)) {
            const prom = addTeam(name, team);
            if (prom) {
                prom.then(() => console.log('child successfully created'))
                    .catch(error => console.log('Error in creating child:', error));
            }
        } 
    }

    const handleDelete = item => {
        console.log('Deleting child team, not implemented yet:', item);
        if (item && item.roles.length === 0) {
            const prom = deleteTeam(item.id);
            if (prom) {
                prom.then(() => console.log('child successfully deleted'))
                    .catch(error => console.log('Error in deleting child:', error));
            }
        } else {
            console.log("Can't delete a team which still have roles");
        }
    }

    return (
        <Section title={`${team ? team.name : '...'} children`} >
            <Chips
                values={childrenTeams}
                onSelect={handleSelect}
                onCreate={handleCreate}
                createMessage={`Enter the new team name to be created as a child to ${team ? team.name : '...'}`} 
                onDelete={handleDelete}
                onDeleteMessage={`Warning, confirming will mean complete deletion of the team, its roles and all dependencies to members. Think twice about it !`}
            />
            {activeChild ? 
                <TeamInfo team={activeChild} setTeam={setTeam} /> : ''
            }
        </Section>
    );
}

export default Children;