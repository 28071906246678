import * as ROUTES from '../../routes';

const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    //url: `https://paof-9cfe9.firebaseapp.com${ROUTES.SIGN_IN_CHECK}`,
    url: `https://yorga.team${ROUTES.SIGN_IN_CHECK}`,
    // This must be true.
    handleCodeInApp: true,
    iOS: {
        bundleId: 'yorga.team'
    },
    android: {
        packageName: 'yorga.team',
        installApp: true,
        minimumVersion: '12'
    },
    dynamicLinkDomain: 'yorga.page.link'
}

export default actionCodeSettings;
