import React from 'react';
import Section from '../Section';
import useTeams from '../../base/useTeams';
import Chips from '../Chips';
import TeamAvatar from '../Teams/TeamAvatar';

const TeamPicker = ({orga, member, updateMember}) => {
    const [teams] = useTeams();
    const myTeams = teams && member && member.teams ? teams.filter(item => member.teams.includes(item.id)) : [];
    const team = member ? member.defaultTeam : null;

    const selectTeam = item => {
        if (item && item.id !== team) {
            const newRole = item.roles.find(r => member.roles.includes(r));
            if (!newRole) {
                console.log("TeamPicker selectTeam: error, no role for me in a team I'm a member of !");
            }
            updateMember({
                defaultTeam: item.id,
                defaultRole: newRole,
            });
        }
    }

    return (
        <>
            {team && orga && myTeams ?
                <Section title={`My teams at ${orga.name}`} startOpen >
                    <Chips 
                        values={myTeams}
                        active={team}
                        avatar={t => 
                            <TeamAvatar team={t} size={24} /> 
                        }
                        onSelect={selectTeam}
                    />
                </Section> : ''
            }
        </>
    );
}

export default TeamPicker;