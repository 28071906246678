import React from 'react';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import GitHubIcon from '@material-ui/icons/GitHub';

const memberLinks = [
    {fieldName: 'linkedIn', icon: <LinkedInIcon />, label: 'LinkedIn'},
    {fieldName: 'twitter', icon: <TwitterIcon />, label: 'Twitter'},
    {fieldName: 'facebook', icon: <FacebookIcon />, label: 'Facebook'},
    {fieldName: 'gitHub', icon: <GitHubIcon />, label: 'GitHub'},
];

export default memberLinks;