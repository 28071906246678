import React, { useState, useEffect } from 'react';
import useRoles from '../../base/useRoles';
import Section from '../Section';
import Chips from '../Chips';
import FormText from '../Form/FormText';
import FormMember from '../Form/FormMember';
import SectionForm from '../Form/SectionForm';
import FormList from '../Form/FormList';
import FormSymbol from '../Form/FormSymbol';

const Roles = ({theme, user, role, team, member, updateMember}) => {
    const [roles, addRole, setRole, deleteRole] = useRoles();
    const [activeRole, setActiveRole] = useState(role);
    const teamRoles = team && roles && team.roles ? roles.filter(item => team.roles.includes(item.id)) : []; 

    useEffect(() => {
        //console.log('change role', role);
        setActiveRole(role);
    }, [role]);

    const handleSelect = item => {
        if (member && member.id === item.holder) {
            updateMember({defaultRole: item.id});
        }
        setActiveRole(item);
    }

    const handleDelete = item => {
        console.log('Deleting role, not tested yet', item);
        deleteRole(item.id);
    }

    const handleCreate = item => {
        console.log('Creating role, not tested yet', item);
        const prom = addRole(item);
        if (prom) {
            prom.then(() => console.log(`Role ${item} created.`)).catch(error => console.log(error));
        } 
    }

    const updateRole = update => {
        setRole(activeRole.id, update);
    }

    return (
        <>
            { role ?
                <Section title={`${team ? team.name : '...'} roles`} startOpen>
                    <Chips
                        values={teamRoles}
                        active={activeRole ? activeRole.id : null}
                        onSelect={handleSelect}
                        onDelete={handleDelete}
                        onCreate={handleCreate}
                        createMessage={`Enter the new role name for team ${team ? team.name : '...'}`} 
                        deleteMessage={`Are you sure you want to delete this role ? You'll lose all related information.`}
                        iconColor={theme.palette.primary.contrastText}
                        isOutlined={item => item.holder !== member.id}
                    />
                </Section> : ''
            }
            {activeRole ?
                <SectionForm title={`Role ${activeRole.name}`} object={activeRole} setObject={updateRole} startOpen>
                    <FormSymbol 
                        fieldName='symbol'
                        label='Symbol'
                        size='50'
                        width={3}
                    />
                    <FormText 
                        fieldName='name'
                        label='role name'
                        width={3}
                    />
                    <FormMember 
                        fieldName='holder'
                        label='holder'
                        width={6}
                    />
                    <FormText 
                        fieldName='purpose'
                        label='purpose'
                        width={12}
                    />
                    <FormList
                        fieldName='duties'
                        label='duty'
                        width={12}
                    />
                </SectionForm> : ''
            }
        </>
    );
}

export default Roles;