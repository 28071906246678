import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import OpenIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/ArrowDropUp';
import RoleIcon from '../NavBar/RoleIcon';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: '10px',
    },
    left: {
        display: 'flex',
    },
    typo: {
        padding: '7px',
    },
    button: {
        maxHeight: '16px',
    },
}));

const Detail = ({role, members, teams}) => {
    const [roleMember, setRoleMember] = useState([]);
    const [roleTeam, setRoleTeam] = useState([]);

    useEffect(() => {
        if (role && role.team) {
            const newRoleTeam = teams.find(doc => role.team === doc.id);
            setRoleTeam(newRoleTeam);
        }
    }, [teams, role]);
    
    useEffect(() => {
        if (role && role.holder) { 
            const newRoleMember = members.find(doc => role.holder === doc.id);
            setRoleMember(newRoleMember);
        }
    }, [members, role]);
    
    return (
        <Grid container >
            <Grid item xs={4} >
                <p><i><small>duties:</small></i></p>
                {role.duties.map((duty, idx) => 
                    <span key={'duty-'+idx}><small>{duty}, </small></span>
                )}
            </Grid>
            <Grid item xs={4} >
                <p><i><small>team:</small></i></p>
                <span><small>{roleTeam.name} </small></span>
            </Grid>
            <Grid item xs={4} >
                <p><i><small>holder:</small></i></p>
                <span><small>{`${roleMember.name} ${roleMember.lastName}`} </small></span>
            </Grid>
         </Grid>
    )
}

const Role = ({role, members, teams}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <Grid container className={classes.root}>
            <Grid item xs={4} className={classes.left} >
                {role.symbol ? <RoleIcon role={role} /> : ''}
                <Typography className={classes.typo} >
                    {role.name}&nbsp;
                </Typography>
            </Grid>
            <Grid item xs={7} className={classes.typo} >
                <Typography  variant='body2' >
                    <i>purpose:&nbsp;</i>{role.purpose}&nbsp;
                </Typography>
            </Grid>
            {members && teams ? 
                <Grid item xs={1}>
                    <Button size='small' onClick={() => setOpen(!open)} className={classes.button}>
                        {open ? <CloseIcon /> : <OpenIcon />}
                    </Button>
                </Grid> : ''
            }
            {open ?
                <Detail role={role} members={members} teams={teams} /> : ''
            }
        </Grid>
    )
}

export default Role;