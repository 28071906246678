import React, { useState, useEffect } from 'react';
import { Grid, Button, IconButton, Paper } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/Visibility';
import OpenIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/ArrowDropUp';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from '../Spinner';
import getUpdate from '../../base/getUpdate';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
    },
    grid: {
        padding: theme.spacing(2),
    },
    buttons: {
        display: 'flex',
        paddingLeft: '20px',
        paddingBottom: '20px',
    },
    cancel: {
        marginRight: '20px',
    },
    open : {
        display: 'flex',
    },
    right: {
        flexGrow: '1',
    },
}));

// Display a form with Form children components as the fields, using 'object' fields 
// as default values, and calling setObject with new values when submitted.
// Note the children MUST have a 'fieldName' property with the name of the field
// they should modify in the object as its value. they must use the automatically
// provided 'value' prop as field value, and should modify it with the automatically
// provided 'setValue' function in their handleChange function.

const SectionForm = ({title, object, setObject, children, startOpen}) => {
    const classes = useStyles();
    const [pendingObject, setPendingObject] = useState(object);
    const [editForm, setEditForm] = useState(false);
    const [touched, setTouched] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(startOpen);

    useEffect(() =>{
        //console.log('Form refreshing pendingObject', pendingObject);
        setPendingObject(object);
    }, [object])

    const setField = (fieldName, fieldValue) => {
        //console.log('Form, setField: ', fieldName, fieldValue);
        if (pendingObject[fieldName] !== fieldValue) {
            const newObject = {...pendingObject};
            newObject[fieldName] = fieldValue;
            setPendingObject(newObject);
            setTouched(true);
        }
    }

    const submit = event => {
        event.preventDefault();
        //console.log('Form submitted');
        //console.log(pendingObject);
        setTouched(false);
        const prom = setObject(getUpdate(pendingObject, object));
        if (prom && prom.then) { // provided function returned a promise
            setLoading(true);
            prom
                .then(() => setLoading(false))
                .catch(error => {
                    console.log(`Error ${error} in submitting object`, object);
                    setLoading(false);
                });
        }
    }

    const cancel = () => {
        //console.log('Form canceled: ', object, pendingObject);
        setPendingObject({...object});
        setTouched(false);
    }

    return (
        <Paper className={classes.root}> { pendingObject ?
            <form onSubmit={submit} noValidate autoComplete="off">
                <Grid container  >
                    <Grid item xs={open ? 6 : 12} >
                        <Button 
                            className={classes.open} 
                            onClick={() => setOpen(!open)} 
                            color='default'
                            display='inline'
                        >
                            {open ? <CloseIcon /> : <OpenIcon />}
                            {title}
                        </Button>
                    </Grid>
                    { open ?
                        <Grid item xs={6} className={classes.buttons} >
                            <span className={classes.right} />
                            { loading ? <Spinner /> : ''}
                            { editForm ? 
                                < IconButton size='small' onClick={() => setEditForm(false)} color='default'>
                                    { touched ? '' : <ViewIcon /> }
                                </IconButton> :
                                < IconButton size='small' onClick={() => setEditForm(true)} color='default'>
                                    < EditIcon />
                                </IconButton>
                            }
                            { touched ? 
                                <>
                                    <Button onClick={cancel} className={classes.cancel} size='small'>
                                        Cancel
                                    </Button> 
                                    <Button type='submit' color='primary' variant='contained' size='small'>
                                        Publish
                                    </Button> 
                                </> : ''
                            }
                        </Grid> : ''
                    }
                </Grid>
                { open ? <Grid container className={classes.grid}>
                    {React.Children.map(children, child => 
                        <Grid item xs={child.props.width || 3} className={classes.grid}> 
                            {child.props.fieldName ? 
                                React.cloneElement(child, {
                                    value: pendingObject[child.props.fieldName] || '',
                                    setValue: editForm ? newValue => setField(child.props.fieldName, newValue) : null,
                                }) : child
                            } 
                        </Grid>
                    )}
                </Grid> : ''}
            </form> : ''
        } </Paper>
    );
}

export default SectionForm;