import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Grid, Link, Typography, Button, Tooltip } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import OpenIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/ArrowDropUp';
import memberLinks from '../Me/memberLinks';

const useStyles = makeStyles((theme) => ({
    left: {
        display: 'flex',
    },
    typo: {
        padding: '7px',
    },
    button: {
        maxHeight: '16px',
    },
}));

const Detail = ({member, teams, roles}) => {
    const [memberTeams, setMemberTeams] = useState([]);
    const [memberRoles, setMemberRoles] = useState([]);

    useEffect(() => {
        const newTeams = teams.filter(doc => member.teams.includes(doc.id));
        //console.log('test teams', newTeams);
        setMemberTeams(newTeams);
    }, [member, teams]);
    
    useEffect(() => {
        const newRoles = roles.filter(doc => member.roles.includes(doc.id));
        //console.log('test roles', newRoles);
        setMemberRoles(newRoles);
    }, [member, roles]);
    
    return (
        <Grid container>
            {memberRoles.length === 0 ? <>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='body2' >No role yet...</Typography> 
                </Grid>
            </> : ''}
            {memberTeams.map(team => <Grid container key={team.id} >
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={4}>
                    <Typography variant='body2' >
                        <i>team: </i>{team.name}
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant='body2' >
                        <i>roles: </i>
                        {memberRoles.map(role => team.roles.includes(role.id) ? 
                            <span key={role.id} ><small>{role.name},&nbsp;</small></span> : ''
                        )}
                    </Typography>
                </Grid>
            </Grid>)}
        </Grid>
    )
}

const Member = ({member, teams, roles}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <Grid container >
            <Grid item xs={6} className={classes.left} >
                <Avatar name={member.name} surname={member.lastName} src={member.avatarUrl} >
                    {member.initials}
                </Avatar>
                <Typography className={classes.typo} >
                    {member.name}&nbsp;
                    {member.lastName}&nbsp;
                </Typography>
            </Grid>
            <Grid item xs={5} className={classes.typo} >
                { member.email ?
                    <Tooltip title={member.email} placement='right' >
                        <Link href={`mailto://${member.email}`} color='inherit' >
                            <EmailIcon size='small' />&nbsp;
                        </Link> 
                    </Tooltip>: ''
                }
                { member.phone ?
                    <Tooltip title={member.phone} placement='right' >
                        <Link href={`tel://${member.phone}`} color='inherit' >
                            <PhoneIcon size='small' />&nbsp;
                        </Link> 
                    </Tooltip>: ''
                }
                {memberLinks.map(link => {
                    const url = member[link.fieldName];
                    return (url ? 
                        <Tooltip key={link.fieldName} title={link.label} placement='right' >
                            <Link href={url} color='inherit' target='_blank' >
                                {link.icon}&nbsp; 
                            </Link> 
                        </Tooltip>: ''
                    )
                })}
            </Grid>
            {teams && roles ? 
                <Grid item xs={1}>
                    <Button size='small' onClick={() => setOpen(!open)} className={classes.button}>
                        {open ? <CloseIcon /> : <OpenIcon />}
                    </Button>
                </Grid> : ''
            }
            {open ?
                <Detail member={member} teams={teams} roles={roles} /> : ''
            }
        </Grid>
    )
}

export default Member;