import React, { useState, useEffect } from 'react';
import * as colors from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';

export const hues = Object.keys(colors).slice(1, 17);
export const shades = [900, 800, 700, 600, 500, 400, 300, 200, 100, 50, 'A700', 'A400', 'A200', 'A100'];
export const darkShades = [900, 800, 700, 600, 500, 400,'A700'];
export const lightShades = [400, 300, 200, 100, 50, 'A400', 'A200', 'A100'];
export const getColor = (hue, shade) => colors[hue][shade];

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 115,
    },
    formControlShade: {
        margin: theme.spacing(1),
        minWidth: 65,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    gommette: {
        padding: '15px',
    },
    button: {
        margin: '15px',
    },
    title: {
        paddingTop: '23px',
        paddingRight: '15px',
        width: '100px',
    },
}));

const FormColor = ({label, value, setValue}) => {
    const [hue, setHue] = useState(value && value.hue ? value.hue : 'green');
    const [lightShade, setLightShade] = useState(value && value.lightShade ? value.lightShade : 400);
    const [darkShade, setDarkShade] = useState(value && value.darkShade ? value.darkShade : 800);
    const classes = useStyles();

    useEffect(() => {
        //console.log('color changed in ColorPicker');
        //console.log(color);
        setHue(value ? value.hue : 'green');
        setDarkShade(value ? value.darkShade : 800);
        setLightShade(value ? value.lightShade : 400);
    }, [value]);

    const handleChangeHue = event => {
        setValue({hue: event.target.value, lightShade, darkShade});
    }

    const handleChangeLightShade = event => {
        setValue({hue, lightShade: event.target.value, darkShade});
    }

    const handleChangeDarkShade = event => {
        setValue({hue, lightShade, darkShade: event.target.value});
    }

    return (
        <span className={classes.root}>
            { setValue ? 
                <>
                    <Typography className={classes.title}>
                        {label}
                    </Typography>
                    <FormControl className={classes.formControl} >
                        <InputLabel id="hue-label">Hue</InputLabel>
                        <Select
                            labelId="hue-label-id"
                            id="hue-id"
                            value={hue}
                            onChange={handleChangeHue}
                        >
                            { hues.map(hue => (
                                <MenuItem key={hue} value={hue}>{hue}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControlShade} >
                        <InputLabel id="shade-label">Light</InputLabel>
                        <Select
                            labelId="shade-label-id"
                            id="light-shade-id"
                            value={lightShade}
                            onChange={handleChangeLightShade}
                        >
                            { lightShades.map(shade => (
                                <MenuItem key={shade} value={shade}>{shade}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControlShade} >
                        <InputLabel id="shade-label">Dark</InputLabel>
                        <Select
                            labelId="shade-label-id"
                            id="dark-shade-id"
                            value={darkShade}
                            onChange={handleChangeDarkShade}
                        >
                            { darkShades.map(shade => (
                                <MenuItem key={shade} value={shade}>{shade}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <span className={classes.gommette}>
                        <svg width='30' height='30' viewBox="0 0 100 100" >
                            <path d="M100,50 a1,1 0 0,0 -100,0" fill={getColor(hue, lightShade)} />
                            <path d="M0,50 a-1,1 0 0,0 100,0" fill={getColor(hue, darkShade)} />
                        </svg>
                    </span>
                </> : ''
            }
        </span>
    );
}

export default FormColor;