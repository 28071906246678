import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Icon from './Icon';
import * as ROUTES from '../../routes';

const MenuDrawer = ({menuOpen, setMenuOpen, navigate}) => {
 
    return (
        <Drawer
            anchor="right"
            open={menuOpen}
        >
            <List>
                <ListItem button onClick={() => setMenuOpen(false)}>
                    <ListItemIcon>
                        <Icon name='closeMenu' />
                    </ListItemIcon>
                </ListItem>
                <Divider />
                <ListItem button onClick={() => {navigate(ROUTES.DECISIONS); setMenuOpen(false)}}>
                    <ListItemIcon>
                        <Icon name='decision' />
                    </ListItemIcon>
                    <ListItemText primary='Decisions' />
                </ListItem>
                <ListItem button onClick={() => {navigate(ROUTES.DIRECTORY); setMenuOpen(false)}}>
                    <ListItemIcon>
                        <Icon name='directory' />
                    </ListItemIcon>
                    <ListItemText primary='Directory' />
                </ListItem>
                <ListItem button onClick={() => {navigate(ROUTES.CHART); setMenuOpen(false)}}>
                    <ListItemIcon>
                        <Icon name='chart' />
                    </ListItemIcon>
                    <ListItemText primary='Charts & Data' />
                </ListItem>
                <ListItem button onClick={() => {navigate(ROUTES.TEMPLATES); setMenuOpen(false)}}>
                    <ListItemIcon>
                        <Icon name='templates' />
                    </ListItemIcon>
                    <ListItemText primary='Templates' />
                </ListItem>
                <ListItem button onClick={() => {navigate(ROUTES.BASECHECK); setMenuOpen(false)}}>
                    <ListItemIcon>
                        <Icon name='question' />
                    </ListItemIcon>
                    <ListItemText primary='Base checking' />
                </ListItem>
            </List>
        </Drawer>
    );
}

export default MenuDrawer;