import React, { useState } from 'react';
import useTopics from '../../base/useTopics';
import Section from '../Section';
import Chips from '../Chips';
import Topic from './Topic';
import { useGlobalState } from '../../globalState';

const Topics = ({team}) => {
    const [user] = useGlobalState('currentUser');
    const [role] = useGlobalState('currentRole');
    const [topics, addTopic, updateTopic, deleteTopic, archiveTopic] = useTopics(true);
    const [activeTopic, setActiveTopic] = useState(null);
    const [theme] = useGlobalState('theme');
    const color = theme ? theme.palette.softText : '#F00';

    const handleSelect = val => {
        setActiveTopic(!activeTopic || val.id !== activeTopic.id ? val : null);
    }

    const handleCreate = title => {
        if (title) {
            const prom = addTopic({title, description: '...', proposition: 'to be discussed'});
            if (prom) {
                prom.then(() => {
                    console.log('topic successfully created');
                    setActiveTopic(null);
                }).catch(error => console.log('Error in creating topic:', error));
            }
        } 
    }

    const handleDelete = topicId => {
        if (topicId) {
            const prom = deleteTopic(topicId);
            if (prom) {
                prom.then(() => {
                    console.log('topic successfully deleted');
                    setActiveTopic(null);
                }).catch(error => console.log('Error in deleting topic:', error));
            }
        }
    }

    const handleArchive = (topic) => {
        if (topic) {
            const prom = archiveTopic(topic);
            if (prom) {
                prom.then(() => {
                    console.log('topic successfully archived');
                    setActiveTopic(null);
                }).catch(error => console.log('Error in archiving topic:', error));
            }
        }
    }

    return (
        <Section title={`${team ? team.name : '...'} topics`} startOpen >
            <Chips
                values={topics}
                label='title'
                active={activeTopic ? activeTopic.id : null}
                onSelect={handleSelect}
                onCreate={handleCreate}
                createMessage={`Enter the new topic title to be created`} 
                isOutlined={item => user && item.author.email !== user.email}
            />
            {activeTopic ? 
                <Topic 
                    team={team}
                    role={role}
                    topic={activeTopic} 
                    color={color}
                    updateTopic={update => updateTopic(activeTopic.id, update)} 
                    archiveTopic={(update) => handleArchive({...activeTopic, ...update})}
                    deleteTopic={() => handleDelete(activeTopic.id)}
                /> : ''
            }
        </Section>
    );
}

export default Topics;