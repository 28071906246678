import * as app  from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import firebaseConfig from './firebaseConfig';
import { setAuth, setBase, setCurrentAuthUser, setStorage, setAnalytics } from '../globalState';

const initBase = () => {
    if (!app.apps.length) { // to avoid multiple calls on hot reload
        //console.log('Init base: ', firebaseConfig);
        app.initializeApp(firebaseConfig);
        const auth = app.auth();
        const analytics = app.analytics();
        const base = app.firestore();
        const storage = app.storage();
        auth.onAuthStateChanged(() => {
            setCurrentAuthUser(auth.currentUser);
        });
        //console.log('base: ', base);
        setAuth(auth);
        setBase(base);
        setStorage(storage);
        setAnalytics(analytics);
    } else {
        console.log('Base already initialized');
    }
}

export default initBase;