import React from 'react';
import OrganizationPicker from './OrganizationPicker';
import TeamPicker from './TeamPicker';
import Profile from './Profile';
import Settings from './Settings';

const Me = ({darkMode, switchMode, user, updateUser, orga, member, updateMember}) => {

    return (
        <>{ user ? <>
            <OrganizationPicker user={user} updateUser={updateUser} /> 
            <TeamPicker orga={orga} member={member} updateMember={updateMember} />
            <Profile orga={orga} member={member} updateMember={updateMember} />
            <Settings darkMode={darkMode} switchMode={switchMode} user={user} updateUser={updateUser} />
        </> : ''}</>
    );
}

export default Me;
