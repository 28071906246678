import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import * as ROUTES from '../../routes';
import { useGlobalState } from '../../globalState';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowIcon from '@material-ui/icons/ArrowRight';
import YorgaLogo from '../icons/YorgaLogo';
import Icon from './Icon';

import NavBarButton from './NavBarButton';
import Arrow from '../icons/Arrow';
import MenuDrawer from './MenuDrawer';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    bar: {
        margin: '0px',
        padding: '0px',
    },
    titleFlex: {
        flex: 1,
    },
    toolBar: {
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    orgaName: {
        paddingLeft: '15px',
        paddingRight: '15px',
    },
    menuButtonTitle: {
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    typo: {
        paddingLeft: '8px',
        paddingRight: '8px',
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {
        fontSize: '30px',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    logo: {
        position: 'relative',
        top: '3px',
    },
    typoNumber: {
        fontSize: '12px',
        background: theme.palette.info,
    },
    topGap: {
        padding: '5px',
    },
}));

const NavBar = ({user, orga, team, role}) => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation().pathname;
    const [menuOpen, setMenuOpen] = useState(false);
    const [theme] = useGlobalState('theme');
    const color = theme ? theme.palette.primary.contrastText : '#F00';

    const navigate = (url) => {
        history.push(url);
    }

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.bar} >
                <Toolbar className={classes.toolBar} variant='dense' >
                    {!user && location !== ROUTES.SIGN_IN_FORM ? 
                        <Typography >
                            Start by sigin in <Arrow />
                        </Typography> : ''
                    }
                    {user && !orga && location !== ROUTES.ME ? 
                        <Typography >
                            Pick an organization <Arrow />
                        </Typography> : ''
                    }
                    {user ?
                        <NavBarButton 
                            iconName='account' 
                            label={`Me`} 
                            route={ROUTES.ME}
                            user={user}
                        /> : 
                        <NavBarButton 
                            iconName='signin' 
                            label='Sign In' 
                            route={ROUTES.SIGN_IN_FORM} 
                        /> 
                    }
                    {role ?
                        <>
                            <ArrowIcon />
                            <NavBarButton 
                                iconName='myRole' 
                                label={`as role ${role.name || '...'}`} 
                                route={ROUTES.ROLES} 
                                role={role}
                                color={color}
                            /> 
                        </> : ''
                    }
                    {team && team.name ?
                        <>
                            <ArrowIcon />
                            <NavBarButton 
                                iconName='myTeam' 
                                label={`in ${team.name}`} 
                                route={ROUTES.TEAMS} 
                                team={team}
                            /> 
                        </>: ''
                    }
                    <ArrowIcon />
                    {orga ?
                        <NavBarButton 
                            iconName='orga' 
                            label={`at ${orga.name}`} 
                            route={ROUTES.ORGA} 
                            orga={orga}
                        /> :
                        <Tooltip title='What is Yorga ?' > 
                            <Button className={classes.menuButtonTitle} onClick={ () => navigate(ROUTES.LANDING) }>
                                <Typography className={classes.title} variant="h6" noWrap>
                                    y<span className={classes.logo}><YorgaLogo size='23' begin='1s' repeatCount='1'/></span>rga
                                </Typography>
                            </Button> 
                        </Tooltip>  
                    }
                    <span className={classes.titleFlex} />
                    <IconButton onClick={() => setMenuOpen(!menuOpen)} >
                        <Icon name='menu' />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <p className={classes.topGap}>&nbsp; </p>
            <MenuDrawer menuOpen={menuOpen} setMenuOpen={setMenuOpen} navigate={navigate} />
        </div>
    );
}

export default NavBar;