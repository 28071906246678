import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CssBaseline from "@material-ui/core/CssBaseline";
import * as ROUTES from '../../routes';
import { SignInForm, SignInCheck } from '../SignIn';
import NavBar from '../NavBar';
import Decisions from '../Decisions';
import Subscribe from '../Subscribe';
import Teams from '../Teams';
import Organization from '../Organization';
import Directory from '../Directory';
import Me from '../Me';
import Roles from '../Roles';
import Chart from '../Chart';
import Templates from '../Templates';
import BaseCheck from '../BaseCheck';
import useRenderCount from "@hooks/render-count";

const YorgaRouter =  ({user, orga, member, team, role, updateOrga, updateMember, updateUser, updateTeam, darkMode, switchMode, localTheme}) => {
    const count = useRenderCount();

    return (
        <Router >
            <CssBaseline />
            <NavBar user={user} orga={orga} team={team} role={role}/>
            <Route exact path={ROUTES.LANDING} render={() => <Subscribe />} />
            <Route path={ROUTES.SIGN_IN_FORM} render={() => <SignInForm />} />
            <Route path={ROUTES.SIGN_IN_CHECK} render={() => <SignInCheck />} />
            <Route path={ROUTES.ORGA} render={() => 
                <Organization 
                    orga={orga} 
                    updateOrga={updateOrga} 
                />} 
            />
            <Route path={ROUTES.ROLES} render={() => 
                <Roles 
                    theme={localTheme}
                    user={user} 
                    orga={orga} 
                    team={team} 
                    role={role} 
                    member={member} 
                    updateMember={updateMember}
                />} 
            />
            <Route path={ROUTES.TEAMS} render={() => 
                <Teams user={user} role={role} team={team} setTeam={updateTeam}
                />} 
            />
            <Route path={ROUTES.DECISIONS} render={() => <Decisions />} />
            <Route path={ROUTES.DIRECTORY} render={() => 
                <Directory 
                    orga={orga}
                />} 
            />
            <Route path={ROUTES.CHART} render={() => 
                <Chart 
                    orga={orga}
                />} 
            />
            <Route path={ROUTES.TEMPLATES} render={() => 
                <Templates 
                    user={user}
                />} 
            />
            <Route path={ROUTES.ME} render={() => 
                <Me 
                    darkMode={darkMode} 
                    switchMode={switchMode} 
                    user={user}
                    updateUser={updateUser}
                    orga={orga}
                    member={member}
                    updateMember={updateMember}
                />
            } />
            <Route path={ROUTES.BASECHECK} render={() => <BaseCheck />} />
            <p><small>&nbsp;rendered {count} times</small></p>
        </Router>
    );
}

export default YorgaRouter;
