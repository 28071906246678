import { createGlobalState } from 'react-hooks-global-state';

const { setGlobalState, getGlobalState, useGlobalState } = createGlobalState({
    base: null,
    auth: null,
    storage: null,
    currentAuthUser: null,
    currentUser: null,
    currentOrga: null,
    currentTeam: null,
    currentRole: null,
    currentMember: null,
    analytics: null,
    theme: null,
    team: null,
});

// to be used once in initBase
export const setBase = (base) => {
    setGlobalState('base', base);
};

// to be used once in initBase
export const setAuth = (auth) => {
    setGlobalState('auth', auth);
};

// to be used once in initBase
export const setStorage = (storage) => {
    setGlobalState('storage', storage);
};

// to be used once in initBase, will be called everytime the user changed
export const setCurrentAuthUser = (currentAuthUser) => {
    setGlobalState('currentAuthUser', currentAuthUser);
};

// to be used once in initBase, will be called everytime the user changed
export const setCurrentUser = (currentUser) => {
    setGlobalState('currentUser', currentUser);
};

// to be used once in App, will be called everytime the orga changed
export const setCurrentOrga = (currentOrga) => {
    setGlobalState('currentOrga', currentOrga);
};

// to be used once in App, will be called everytime the team changed
export const setCurrentTeam = (currentTeam) => {
    setGlobalState('currentTeam', currentTeam);
};

// to be used once in App, will be called everytime the role changed
export const setCurrentRole = (currentRole) => {
    setGlobalState('currentRole', currentRole);
};

// to be used once in App, will be called everytime the role changed
export const setCurrentMember = (currentMember) => {
    setGlobalState('currentMember', currentMember);
};

export const setTheme = (theme) => {
    setGlobalState('theme', theme);
};

export const setAnalytics = (analytics) => {
    setGlobalState('analytics', analytics);
};

export { useGlobalState, getGlobalState };