import React from 'react';
import SectionForm from '../Form/SectionForm';
import FormImage from '../Form/FormImage';
import FormText from '../Form/FormText';
import FormColor from '../Form/FormColor';

const OrgaSettings = ({orga, updateOrga}) => {
    //console.log('Going through OrgaSettings');
    //console.log(orgaInfo);

    return (
        <>{orga ? 
            <SectionForm title={`${orga.name} settings`} object={orga} setObject={updateOrga} >
                <FormImage fieldName='logoUrl' path='logos' name={orga.id} width={3}/>
                <FormText fieldName='name' label='organization name'  width={3} />
                <FormText fieldName='email' label='yorga contact email'  width={3}/>
                <FormText fieldName='phone' label='yorga contact phone'  width={3}/>
                <FormText fieldName='welcome' label='welcome message' multiline rows='4' width={12} />
                <FormColor fieldName='primaryColor' label='Primary'  width={6}/>
                <FormColor fieldName='secondaryColor' label='Secondary'  width={6}/>
            </SectionForm> : ''
        }</>
    )
}

export default OrgaSettings;