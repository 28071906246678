import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles';
import Icon from './Icon';

const useStyles = makeStyles((theme) => ({
    inactive: {
        height: '50px',
        width: '50px',
    },
    active: {
        height: '50px',
        width: '50px',
        borderBottom: `2px solid ${theme.palette.primary.active}`,
    },
}));

const NavBarButton = (props) => {
    const history = useHistory();
    const path = useLocation().pathname;
    const active = (path === props.route);
    const classes = useStyles();

    const navigate = () => {
        if (!active) {
            history.push(props.route);
        }
    }
    
    return (
        <Tooltip title={props.label}>
            <Button
                onClick={navigate}
                className={active ? classes.active : classes.inactive}
                size='small'
            >
                <Icon name={props.iconName} {...props}/>
            </Button>
        </Tooltip>
    )
}

export default NavBarButton;