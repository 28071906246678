import { getGlobalState, setCurrentUser } from '../globalState';

const logout = () => {
    const auth = getGlobalState('auth');
    
    if (auth) {
        setCurrentUser(null);
        auth.signOut().then(() => { 
            console.log('Logging out');
        });
    }
}

export default logout;