import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button } from '@material-ui/core';
import OpenIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/ArrowDropUp';
import TeamAvatar from '../Teams/TeamAvatar';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: '10px',
    },
    left: {
        display: 'flex',
    },
    typo: {
        padding: '7px',
    },
    button: {
        maxHeight: '16px',
    },
}));

const Detail = ({team, members, roles}) => {
    const [teamMembers, setTeamMembers] = useState([]);
    const [teamRoles, setTeamRoles] = useState([]);

    useEffect(() => {
        if (team && team.roles && team.roles.length > 0) {
            const newRoles = roles.filter(doc => team.roles.includes(doc.id));
            //console.log('test roles', newRoles);
            setTeamRoles(newRoles);
        }
    }, [team, roles]);
    
    useEffect(() => {
        if (teamRoles.length > 0) { 
            const newMembers = members.filter(doc => teamRoles.reduce((acc, r) => acc || r.holder === doc.id, false));
            //console.log('test members', newMembers);
            setTeamMembers(newMembers);
        }
    }, [teamRoles, members, team]);
    
    return (
        <Grid container >
            <Grid item xs={4} >
                <p><i><small>duties:</small></i></p>
                {team.duties.map((duty, idx) => 
                    <span key={'duty-'+idx}><small>{duty}, </small></span>
                )}
            </Grid>
            <Grid item xs={4} >
                <p><i><small>roles:</small></i></p>
                {teamRoles.map(role => 
                    <span key={role.id} ><small>{role.name}, </small></span>
                )}
            </Grid>
            <Grid item xs={4} >
                <p><i><small>members:</small></i></p>
                {teamMembers.map(member => 
                    <span key={member.id} ><small>{`${member.name} ${member.lastName}`}, </small></span>
                )}
            </Grid>
         </Grid>
    )
}

const Team = ({team, members, roles}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <Grid container className={classes.root}>
            <Grid item xs={4} className={classes.left} >
                <TeamAvatar team={team} />
                <Typography className={classes.typo} >
                    {team.name}&nbsp;
                </Typography>
            </Grid>
            <Grid item xs={7} className={classes.typo} >
                <Typography  variant='body2' >
                    <i>purpose:&nbsp;</i>{team.purpose}&nbsp;
                </Typography>
            </Grid>
            {members && roles ? 
                <Grid item xs={1}>
                    <Button size='small' onClick={() => setOpen(!open)} className={classes.button}>
                        {open ? <CloseIcon /> : <OpenIcon />}
                    </Button>
                </Grid> : ''
            }
            {open ?
                <Detail team={team} members={members} roles={roles} /> : ''
            }
        </Grid>
    )
}

export default Team;