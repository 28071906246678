import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import OpenIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/ArrowDropUp';
import SectionSearch from '../Section/SectionSearch';
import useMembers from '../../base/useMembers';
import useTeams from '../../base/useTeams';
import useDecisions from '../../base/useDecisions';
import formatDistance from 'date-fns/formatDistance';
import format from 'date-fns/format';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '5px',
    },
    selected: {
        padding: '5px',
        background: theme.palette.text.disabled,
    },
    button: {
        maxHeight: '16px',
    },
}));

const Detail = ({field, value, teams, members}) => {

    const getHolderName = (id) => {
        const member = members.find(doc => doc.id === id);
        return member ? `${member.name} ${member.lastName}` : 'unknown';
    }

    const getTeamName = (id) => {
        const team = teams.find(doc => doc.id === id);
        return team ? team.name : 'unknown';
    }

    return (
        <> {field && !field.startsWith('default') && field !== 'orgaId' && field !== 'id' && field !== 'createdOn' && field !== 'author' && field !== 'decision' && value ?
            <Typography variant='body2' >
                {field} = {
                    field === 'holder' ? getHolderName(value) :
                    (field === 'team' || field === 'parent') ? getTeamName(value) :
                    (field === 'date' || field === 'createdOn') ? format(value, "yy-MM-dd' 'HH:mm") :
                    ['primaryColor', 'secondaryColor', 'color'].includes(field) ? `${value.hue} (${value.lightShade}/${value.darkShade})` :
                    field === 'duties' && value ? value.reduce((acc, value) => acc+', '+value, `[${value.length}]`) :
                    typeof value === 'boolean' ? value ? 'yes' : 'no' :
                    Array.isArray(value) ? `[${value.length}]` : 
                    field.endsWith('Url') ? <a href={value} rel="noopener noreferrer" target='_blank' >link</a> : value
                },  
            </Typography> : ''
        }</>
    );
};


const Decision = ({decision, teams, members}) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    return (
        <Grid container className={open ? classes.selected : classes.root} >
            <Grid item xs={3}>
                <Typography variant='body2'>
                    {formatDistance(decision.date, Date.now(), {includeSeconds: true})} 
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography variant='body2'>
                    {decision.description} 
                </Typography>
            </Grid>
            <Grid item xs={1}>
                <Button size='small' onClick={() => setOpen(!open)} className={classes.button}>
                    {open ? <CloseIcon /> : <OpenIcon />}
                </Button>
            </Grid>
            {open ? 
                <Grid item container xs={12}>
                    <Grid item xs={6} >
                        <i>before</i>
                        {Object.entries(decision.oldValues).map(([field, value], idx) => 
                            <Detail 
                                key={'before'+idx} 
                                field={field} 
                                value={value} 
                                members={members}
                                teams={teams}                                            />
                        )}
                    </Grid>
                    <Grid item xs={6} >
                        <i>after</i>
                        {Object.entries(decision.newValues).map(([field, value], idx) => 
                            <Detail 
                                key={'after'+idx} 
                                field={field} 
                                value={value}
                                members={members}
                                teams={teams}                                            />
                        )}
                    </Grid>
                </Grid> : ''
            }
        </Grid>
    );
}

const Decisions = () => {
    const [decisions] = useDecisions();
    const [members] = useMembers();
    const [teams] = useTeams();

    return (
        <SectionSearch 
            title='Decisions' 
            docs={decisions} 
            fields={['description']} 
            renderDoc={doc => 
                <Decision
                    decision={doc} 
                    key={doc.id} 
                    members={members}
                    teams={teams}
                />            
            }
            limit={50}
            startOpen
        />
    );
}

export default Decisions;
