import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { Grid, IconButton, Typography, Box } from '@material-ui/core';
import useArchives from '../../base/useArchives';
import SectionSearch from '../Section/SectionSearch';
import { useGlobalState } from '../../globalState';
import usePosts from '../../base/usePosts';
import Post from './Post';
import formatDistance from 'date-fns/formatDistance';
import CloseIcon from '@material-ui/icons/ArrowDropUp';
import OpenIcon from '@material-ui/icons/ArrowDropDown';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import Form from '../Form/Form';
import FormText from '../Form/FormText';
import FormDate from '../Form/FormDate';
import TopicAction from './TopicAction';
import Spinner from '../Spinner';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '20px',
    },
    icon: {
        display: 'inline',
        textAlign: 'right',
    },
    detail: {
        paddingBottom: '30px',
    },
    box: {
        padding: '10px',
    },
    decisions: {
        textAlign: 'center',
        paddingTop: '20px',
    },
}));

const ArchiveDetail = ({topic, color, restoreArchive}) => {
    const classes = useStyles();
    const [posts] = usePosts(topic.id);    
    const [loading, setLoading] = useState(false);

    const handleRestore = () => {
        console.log('toto ');
        setLoading(true);
        restoreArchive();
    }

    return (
        <Grid item xs={12} className={classes.detail} >
            <Box borderRadius='borderRadius' border={1 } className={classes.box}>
                <Form object={topic} setObject={null} >
                    <FormText fieldName='title' width={4} disabled label='Title' />
                    <FormDate value={topic.createdOn} disabled width={4} label='Created on'/>
                    <FormText value={topic.author.name} disabled width={4} label='Created by' />
                    <FormText fieldName='description' disabled multiline row={3} label='Rationale' width={12}/>
                    <FormText 
                        fieldName='proposition' 
                        disabled 
                        multiline row={3} 
                        label={topic.decision.status === 'decided' ? 'Decision' : 'Postponed proposition'} 
                        width={12}/
                    >
                </Form>
                {posts.map(post => <Post key={post.id} post={post} color={color} />)}
                <Grid container item xs={12} className={classes.decisions}>
                    <Grid item xs={6} ></Grid>
                    {loading ? 
                        <Spinner on={true} /> :
                        <TopicAction
                            onClick={handleRestore}
                            disabled={topic.decision.status === 'decided'}
                            icons={<><UnarchiveIcon />&nbsp;</>}
                            title='restore this topic as an active topic'
                        />
                    }
                    <Grid item xs={3} ></Grid>
                </Grid> 
            </Box>
        </Grid>
    )
}

const ArchiveLine = ({topic, color, restoreArchive}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(!open);

    return (
        <Grid container>
            <Grid item xs={8}>
                <Typography variant='body2' noWrap>
                    <b>{topic.title}</b>
                    {' - '}
                    {topic.proposition}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography variant='body2' noWrap>
                    {topic.decision.status === 'decided' ? 'decided ' : 'postponed '}
                    {topic.decision.scope === null ? ' publicly ' : ' '}
                    {formatDistance(topic.createdOn, Date.now())}
                    {' ago by role '} 
                    {topic.author.name}
                </Typography>
            </Grid>
            <Grid item xs={1} className={classes.icon} >
                <IconButton size='small' onClick={handleOpen}>
                    {open ? <CloseIcon /> : <OpenIcon />}
                </IconButton>
            </Grid>
            { open ? 
                <ArchiveDetail topic={topic} restoreArchive={restoreArchive} color={color} /> : ''
            }
        </Grid>
    );
}

const Archives = ({team}) => {
    const [archives, restoreArchive] = useArchives();
    const [theme] = useGlobalState('theme');
    const color = theme ? theme.palette.softText : '#F00';

    return (
        <SectionSearch 
            title={`${team ? team.name : '...'} archived topics`} 
            docs={archives} 
            fields={['title', 'description', 'proposition', 'roleName', 'decisionStatus']} 
            renderDoc={doc => 
                <ArchiveLine
                    topic={doc} 
                    color={color}
                    key={doc.id} 
                    restoreArchive={() => restoreArchive(doc.id)}
                />            
            }
            limit={20}
        />
    );
}

export default Archives;