import { useEffect, useState } from 'react';
import { useGlobalState } from '../globalState';
import addListener from './addListener';
import getUpdate from './getUpdate';

const useMembers = () => {
    const [base] = useGlobalState('base');
    const [user] = useGlobalState('currentUser');
    const [documents, setDocuments] = useState([]);
    const orgaId = user ? user.defaultOrga : null;
    const orgaRef = base && orgaId ? base.collection('organizations').doc(orgaId) : null;

    useEffect(() => {
        if (orgaId) {
            const tag = `${orgaId}-members`;
            const collectionRef = orgaRef.collection('members');
            const ref = {
                tag, 
                query: collectionRef.orderBy('name'),
            }
            return addListener(ref, {update: docs => setDocuments(docs)});
        }
    }, [orgaId, orgaRef]);

    // The only member you can add to the current orga is yourself, from
    // useMyInvitations hook, thus this function can only be used to updated a 
    // member document, not to create one.
    const setMember = (id, update) => {
        if (orgaId && id && update) {
            const member = documents.find(doc => doc.id === id);
            const memberRef = orgaRef.collection(`members`).doc(id);
            const batch = base.batch();
            batch.update(memberRef, getUpdate(update, member));
            return batch.commit(); // return the promise
        }        
    }

    return [documents, setMember];
}

export default useMembers;