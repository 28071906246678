import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import useTemplates from '../../base/useTemplates';
import Section from '../Section';
import TemplateLine from './TemplateLine';
import TemplateForm from './TemplateForm';
import { Typography } from '@material-ui/core';

const Templates = ({user}) => {
    const [templates, addTemplate, setTemplate, deleteTemplate] = useTemplates();
    const [activeTemplate, setActiveTemplate] = useState(null);

    const handleAdd = () => {
        console.log('Templates addTemplate - not implemented yet');
        const newTemplate = {name: '', purpose: '', duties: []}
        const prom = addTemplate(newTemplate);
        if (prom) {
            prom.then(() => setActiveTemplate(newTemplate))
                .catch(error => console.log('Unable to create a new template: ', error));
        } else {
            console.log('Unable to create a new template.');
        }
    }

    const handleEdit = (template, update) => {
        console.log('Templates editTemplate - not implemented yet');
        if (template) {
            setTemplate(template, update);
        }
    }

    const handleDelete = template => {
        console.log('Templates deleteTemplate - not implemented yet');
        if (template) {
            deleteTemplate(template);
        }
    }

    const handleSelect = template => {
        setActiveTemplate(activeTemplate && template.id === activeTemplate.id ? null : template);
    }

    return (
        <>
            <Section title='Templates' startOpen >
                {templates.map( temp => 
                    <TemplateLine 
                        key={temp.id} 
                        template={temp} 
                        onClick={() => handleSelect(temp)} 
                        selected={activeTemplate && temp.id === activeTemplate.id}
                    />
                )}
            </Section>
            <Section title={activeTemplate ? activeTemplate.name : ''} startOpen >
                { activeTemplate ?
                    <TemplateForm template={activeTemplate} setTemplate={handleEdit} deleteTemplate={handleDelete}/> : 
                    <Typography>
                        Create a new template as {user ? user.authorName || user.email : '...'}
                        <IconButton onClick={handleAdd} >
                            <AddIcon />
                        </IconButton>
                    </Typography>
                }
            </Section>
        </>
    );
}

export default Templates;