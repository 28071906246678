import { useEffect, useState } from 'react';
import { useGlobalState } from '../globalState';
import addListener from './addListener';

const useArchives = () => {
    const [base] = useGlobalState('base');
    const [user] = useGlobalState('currentUser');
    const [role] = useGlobalState('currentRole');
    const [team] = useGlobalState('currentTeam');
    const [archives, setArchives] = useState([]);
    const orgaId = user ? user.defaultOrga : null;
    const teamId = team ? team.id : null;
    const teamRef = base && orgaId && teamId ? 
        base.collection('organizations').doc(orgaId).collection('teams').doc(teamId) : null;

    useEffect(() => {
        if (teamRef) {
            //console.log('Listening to archives', teamRef);
            const tag = `${teamId}-archives`;
            const collectionRef = teamRef.collection('archives');
            const ref = {
                tag, 
                query: collectionRef.orderBy('createdOn', 'desc').limit(1000),
            }
            return addListener(ref, {update: docs => {setArchives(docs)}});
        }
    }, [teamId, teamRef]);

    const restoreArchive = (id) => {
        if (teamRef && id) {
            const archiveRef = teamRef.collection(`archives`).doc(id);
            const topicRef = teamRef.collection(`topics`).doc(id);
            return base.runTransaction(function(transaction) {
                // This code may get re-run multiple times if there are conflicts.
                return transaction.get(archiveRef).then(function(arcDoc) {
                    if (!arcDoc.exists) {
                        throw Error("Archive does not exist!");
                    }
                    transaction.set(topicRef, {
                        createdOn: Date.now(), 
                        createdBy: role.id,
                        restoredBy: role.name,
                        ...arcDoc.data()
                    });
                    transaction.delete(archiveRef);
                });            
            });
        }        
    }

    return [archives, restoreArchive];
}

export default useArchives;